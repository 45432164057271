import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import Form from '../../components/Form/Form'
import s from "./main-one.module.sass"
import Button from '../../components/UI/Button/Button'
import Checkbox from '../../components/UI/Checkbox/Checkbox'
import Tooltip from '../../components/UI/Tooltip/Tooltip'
import { useNavigate } from 'react-router'
import Background from '../../components/Background/Background'
import AutocompleteInput from '../../components/UI/AutocompleteInput/AutocompleteInput'
import { useDispatch, useSelector } from 'react-redux'
import { setOrder } from '../../redux/reducers/mainReducer'
import ReactTooltipCustomized from '../../components/ReactTooltipCustomized/ReactTooltipCustomized'

const MainOne = () => {

    const navigate = useNavigate()

    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")

    const dispatch = useDispatch()

    const ORDER = useSelector(state => state.order)

    return (
        <Layout>
            <Form>
                <div className={s.main}>
                    <div className={s.row}>
                        <AutocompleteInput
                            label="Transport car FROM"
                            onPlaceSelected={event => setFrom(event.formatted_address)}
                            onChange={event => setFrom(event.target.value)}
                        />
                        <AutocompleteInput
                            label="Transport car TO"
                            onPlaceSelected={event => setTo(event.formatted_address)}
                            onChange={event => setTo(event.target.value)}
                        />
                        <Button
                            disabled={from && to ? false : true}
                            onClick={() => {
                                if (from && to) {
                                    dispatch(setOrder({ ...ORDER.order, address: { origin: from, destination: to } }))
                                    navigate("/home/2")
                                }
                            }}
                            width="12rem"
                        >
                            NEXT STEP
                        </Button>
                    </div>
                    <Tooltip text={"Transport type"} tooltipBody={`Open trailer. <br/>
                    The most common type of trailer is an open multi-car hauler. <br/>
                    It takes up to 9 cars on a single trailer. <br/>
                    When transporting a car on an open trailer, <br/>
                    you should consider the fact that the car is exposed to various weather conditions such as sun, rain, winds, etc during the auto shipping. <br/>
                    <br/>
                    Enclosed trailer. <br/>
                    Enclosed trailers are the safer option and of course are  more expensive than the open trailers. <br/>
                    Expensive cars with low ground clearance are strongly advised to be shipped with an enclosed trailer with lift <br/>gate or low ramps.`}
                    width="35rem"
                    >
                    </Tooltip>
                    <div className={s.checkboxes}>
                        <Checkbox 
                            text="Open" 
                            defaultChecked={true} 
                            type="radio" 
                            name="main-one" 
                            onChange={ () => dispatch( setOrder({...ORDER.order, open: true}) ) }
                        />
                        <Checkbox 
                            text="Enclosed" 
                            type="radio" 
                            name="main-one"
                            onChange={ () => dispatch( setOrder({...ORDER.order, open: false}) ) }
                        />
                    </div>
                </div>
            </Form>
            <Background />
            <ReactTooltipCustomized />
        </Layout>
    )
}

export default MainOne