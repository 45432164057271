import React from 'react'

export default function LogoWhite() {
	return (
		<svg version="1.1" width={60} height={44} id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 1939.31 1191.54" style={{ enableBackground: "new 0 0 1939.31 1191.54" }} xmlSpace="preserve">
			<path className="st0" fill='#fff' d="M106.8,1039.04l-21.53,2.33l3.62,15.23l-12.72,1.67l5.02-16.36l-21.27,2.78c-2.73,0.36-5.04,2.17-6.04,4.73
	L0.23,1187.43c-0.88,2.11,0.86,4.38,3.13,4.08l30.05-3.93c1.94-0.25,3.56-1.62,4.14-3.5l13.33-43.43l56.25-7.36l9.38,39.48
	c0.51,2.16,2.58,3.59,4.78,3.3l30.49-3.99c2.04-0.27,3.3-2.35,2.59-4.28l-41.43-124.83C112.08,1040.38,109.52,1038.74,106.8,1039.04
	z M58.39,1116.18l13.78-44.89l19.6-2.57l9.93,41.79L58.39,1116.18z"/>
			<g id="o3Ths8.tif_10_">
				<g>
					<path className="st0" fill='#fff' d="M1095.73,900.24c7.63-1.07,14.98-2,22.3-3.17c2.97-0.47,4.85,0.62,6.78,2.76
			c20.51,22.66,41.12,45.24,61.68,67.85c0.85,0.93,1.5,2.04,2.24,3.07c0.63-0.19,0.65-0.19,1.28-0.38c0.13-1.72,0.4-3.44,0.41-5.17
			c0.03-24.46,0.09-48.91-0.06-73.37c-0.02-3.63,0.94-5.14,4.69-5.57c8.76-0.99,17.47-2.42,26.65-3.74
			c0.11,2.14,0.24,91.72,0.36,134.95c0.01,3.63-0.84,5.21-4.75,5.51c-5.64,0.44-11.23,1.29-16.78,2.39
			c-3.39,0.67-5.69-0.22-8.01-2.78c-20.32-22.44-40.79-44.75-61.23-67.08c-1.11-1.21-2.4-2.25-3.6-3.37
			c-0.5,0.22-0.55,0.24-1.05,0.46c0,1.67,0,3.33,0,5c0,24.46-0.06,48.91,0.07,73.37c0.02,3.36-0.72,4.92-4.36,5.33
			c-8.77,0.99-17.49,2.42-26.6,3.72V900.24z"/>
					<path className="st0" fill='#fff' d="M1604.06,826.83c37.13,0.1,65.93,27.1,63.13,68.41c-2.56,37.66-30.28,66.26-62.75,74.97
			c-18.29,4.91-36.74,5.84-54.52-2.65c-24.14-11.54-35.65-35.19-33.12-61.98c3.59-38.07,30.1-66.25,59.12-74.16
			C1588.08,827.94,1591.31,827.34,1604.06,826.83z M1585.39,945.9c29.83-0.14,53.33-27.02,49.64-56.79
			c-2.84-22.97-19.32-36.06-42.09-33.44c-27.26,3.13-47.09,28.13-44.24,55.77C1550.88,932.57,1565.18,946,1585.39,945.9z"/>
					<path className="st0" fill='#fff' d="M1783.32,899.53c9.91,13.05,19.74,25.99,30.12,39.66c-4.04,0.71-7.22,1.34-10.42,1.81
			c-6.91,1.02-13.81,2.14-20.75,2.77c-1.56,0.14-3.81-1.16-4.85-2.49c-7.43-9.51-14.72-19.13-21.83-28.88
			c-2.32-3.18-4.63-3.13-8.97-2.57c-6.32,0.82-9.67,1.52-16.32,2.28c-4.83,0.55-7,1.18-6.89,5.66c0.26,10.03,0.06,20.08,0.11,30.11
			c0.01,2.17-0.03,4.15-2.8,4.54c-9.47,1.33-18.94,2.67-29.22,4.13c0-1.99,0-3.36,0-4.73c0-43.36,0.05-86.71-0.08-130.07
			c-0.01-4.06,1.16-5.34,5.28-5.85c19.34-2.41,38.62-5.29,57.93-7.94c11-1.51,21.8-0.69,32.16,3.53c15,6.12,22.72,17.73,24.08,33.47
			c1.91,22.04-6.48,39.41-24.87,51.86C1785.28,897.31,1782.68,898.76,1783.32,899.53z M1723.45,882.45
			c-0.12,3.62,0.92,5.01,4.7,4.41c8.88-1.23,14.4-1.9,23.71-3.45c19.94-3.95,28.85-15.15,26.68-33.08
			c-0.91-7.52-6.39-13.08-12.39-14.71c-4.25-1.16-7.52-1.59-13.34-1.1c-9.74,1.12-15.54,2.02-25.9,3.47
			c-2.63,0.32-3.43,1.45-3.4,3.92C1723.58,848.75,1723.44,873.89,1723.45,882.45z"/>
					<path className="st0" fill='#fff' d="M597.14,1040.31c0.6,36.41-26.16,69.33-61.1,79.3c-16.21,4.63-32.79,6.29-49.3,0.55
			c-24.22-8.42-39.46-29.02-40.34-54.63c-1.4-40.66,25.91-76.47,65.71-85.38c17.08-3.82,34.37-4.82,50.8,3.12
			C586.69,994.79,596.98,1014.57,597.14,1040.31z M477.96,1056.35c0.99,5.24,1.34,10.71,3.08,15.69
			c6.8,19.51,26.75,28.52,47.68,21.94c24.93-7.84,38.67-29.62,35.79-56.72c-2.15-20.15-19.41-34.16-39.54-32.08
			C497.9,1007.99,478.76,1029.08,477.96,1056.35z"/>
					<path className="st0" fill='#fff' d="M920.91,1064.52c-11.45,1.61-21.77,3.18-32.13,4.37c-1.21,0.14-2.98-1.57-3.95-2.84
			c-7.27-9.45-14.51-18.94-21.52-28.58c-2.29-3.15-4.82-3.66-8.77-2.72c-5.97,1.42-12.04,2.36-18.17,2.54
			c-4.15,0.12-5.32,1.56-5.28,4.96c0.14,10.33-0.02,20.66,0.1,30.99c0.03,2.66-0.73,3.94-3.52,4.31
			c-8.51,1.12-16.97,2.55-25.49,3.59c-0.89,0.11-2.25-1.4-2.92-2.46c-0.46-0.73-0.11-1.98-0.11-3c0-42.77,0.06-85.55-0.09-128.32
			c-0.02-4.33,1.1-5.86,5.62-6.43c19.19-2.39,38.29-5.52,57.48-7.93c12.59-1.58,25.04-0.72,36.53,5.48
			c11.96,6.46,18.29,16.85,19.66,30.11c2.33,22.51-5.68,40.47-24.66,53.15c-2.69,1.8-2.6,3.13-0.76,5.5
			c8.29,10.68,16.43,21.48,24.6,32.26C918.56,1060.84,919.43,1062.3,920.91,1064.52z M831.47,1011.9c1.19,0.06,2.19,0.26,3.15,0.13
			c7.93-1.06,15.89-1.98,23.77-3.35c9.21-1.59,17.77-4.64,23.54-12.72c10.27-14.41,4.29-33-11.77-36.24
			c-2.95-0.59-6.13-0.68-9.12-0.33c-8.81,1.04-17.58,2.5-26.38,3.61c-2.79,0.35-3.55,1.6-3.53,4.26c0.1,13.97,0.05,27.95,0.06,41.92
			C831.18,1010.03,831.35,1010.86,831.47,1011.9z"/>
					<path className="st0" fill='#fff' d="M1380.66,929.72c0-21.24,0.14-42.48-0.11-63.72c-0.05-4.45,1.39-6.06,5.65-6.6
			c15.72-2.01,31.37-4.6,47.12-6.38c9.5-1.08,19.16-1.84,28.68-1.37c17.11,0.85,31.74,11.83,35.9,26.65
			c6.23,22.19,0.08,45.44-19.73,60.28c-11.36,8.51-24.4,12.67-38.26,14.63c-7.92,1.12-15.84,2.23-23.78,3.2
			c-2.76,0.34-3.61,1.67-3.58,4.33c0.1,10.28-0.08,20.57,0.11,30.85c0.04,2.39-1.75,4.26-4.11,4.62c-8.99,1.37-14.35,1.9-22.89,3.22
			c-3.99,0.74-5.14-0.25-5.06-4.26c0.21-10.91,0.07-21.82,0.08-32.73C1380.66,951.54,1380.66,940.63,1380.66,929.72z
			 M1412.59,906.12c0,6.99-0.02,13.98,0.01,20.96c0.01,1.73-0.22,3.83,2.6,3.37c11.05-1.8,22.29-2.88,33.08-5.67
			c13.89-3.6,21.6-16.21,19.54-29.54c-1.76-11.37-8.29-17.56-19.88-17.1c-10.72,0.43-21.39,2.2-32.09,3.35
			c-2.56,0.27-3.35,1.64-3.3,4.11C1412.66,892.44,1412.58,899.28,1412.59,906.12z"/>
					<path className="st0" fill='#fff' d="M295.87,1013.24c1.31-0.19,2.5,0.8,2.52,2.12c0.16,10.8,0.38,53.35,0.31,74.63
			c-0.05,17.05-3.03,33.32-13.67,47.31c-9.82,12.92-22.8,20.92-38.63,24.31c-12.12,2.6-24.26,4.45-36.65,1.63
			c-17.26-3.93-28.19-14.63-32.81-31.42c-1.91-6.93-2.89-14.31-3-21.5c-0.36-25.32-0.12-50.65-0.23-75.97
			c-0.01-3.5,1.05-4.62,6.28-4.6c6.71,0.03,13.42-0.86,19.8-2.94c5.59-1.82,5.8,0.27,5.8,3.19c-0.03,16.59-0.08,33.18,0.01,49.78
			c0.06,10.76,0.21,21.53,0.66,32.29c0.13,3.28,1,6.65,2.16,9.74c5.1,13.52,15.96,17.29,31.26,13.88
			c16.81-3.75,26.29-15.05,26.97-32.5c0.83-21.2,0.42-42.44,0.68-63.66c0.07-6.25-0.16-12.5-0.1-18.75
			c0.03-2.68,1.06-3.68,3.88-4.02C279.25,1015.76,287.36,1014.49,295.87,1013.24z"/>
					<path className="st0" fill='#fff' d="M934,1063.46c-1.2,0.14-2.1-1.05-1.65-2.17c2.48-6.09,4.97-12.33,7.36-18.09
			c17.12-41.34,34.29-82.66,51.32-124.04c1.48-3.6,3.6-3.93,8.18-4.57c6.35-0.89,10.56-1.38,16.6-2c8.92-0.92,7.17-1.99,11.37,6.81
			c17.95,37.56,49.22,103.54,56.61,119.27c0.78,1.67-0.25,3.61-2.07,3.91c-3.9,0.65-7.67,1.28-11.45,1.82
			c-4.9,0.71-9.83,1.15-14.72,1.93c-2.52,0.4-3.68-0.58-4.6-2.77c-2.98-7.1-6.3-14.06-9.11-21.22c-1.23-3.13-2.83-3.62-5.96-3.16
			c-18.72,2.74-37.47,5.27-56.22,7.76c-2.65,0.35-4.05,1.41-5,3.98c-3.11,8.45-6.41,16.84-9.85,25.16
			c-0.56,1.36-2.05,3.07-3.33,3.29C954.11,1060.61,939.91,1062.76,934,1063.46z M1008.92,946.37c-0.68,0.01-0.73,0.01-1.41,0.03
			c-6.96,18.07-13.93,36.14-21.29,55.27c15.29-2.15,29.13-4.1,43.94-6.19C1022.87,978.64,1015.89,962.51,1008.92,946.37z"/>
					<path className="st0" fill='#fff' d="M1255.35,980.3c11.92,6.14,24.62,9.32,37.9,8.09c7.85-0.72,19.78-1.83,28.64-8.7
			c3.92-3.52,6.23-7.95,4.71-13.24c-1.67-5.81-6.85-7.85-12.09-8.7c-11.05-1.8-22.21-2.96-33.29-4.57
			c-7.93-1.15-15.79-2.66-22.52-7.54c-6.74-4.88-10.73-11.27-11.5-19.65c-1.96-21.6,8.29-38.9,29.45-49.27
			c8.94-4.38,16.22-6.61,26.11-8.03c10.81-1.55,23.68-1.61,34.33,0.49c4.26,0.84,8.45,2.17,12.5,3.72c2.02,0.75,2.59,2.26,2.04,3.71
			c-2.68,7.66-5.71,15.19-8.44,22.25c-8.47-1.69-16.47-3.77-24.61-4.77c-8.73-1.08-17.64-0.74-25.98,2.31
			c-6.09,2.22-10.95,6.49-13.4,12.83c-2.57,6.65-0.08,12.86,6.77,14.45c8.61,2,17.46,2.92,26.2,4.39c8.31,1.4,16.75,2.32,24.85,4.51
			c13.84,3.74,21.03,13.57,21.33,27.81c0.48,22.62-12.32,36.33-31.2,45.7c-9.74,4.83-20.36,6.22-31.04,7.84
			c-12.1,1.83-23.8,0.83-35.47-1.85c-4.77-1.09-9.36-3.12-13.87-5.07c-0.98-0.43-2.14-2.7-1.8-3.58c2.83-7.31,5.97-14.5,9.07-21.7
			C1254.25,981.25,1254.79,980.9,1255.35,980.3z"/>
					<path className="st0" fill='#fff' d="M363.65,1142.58v-113.44c-14.89,1.99-29.19,3.91-44.12,5.91c0-8.46,0.15-16.27,0.15-24.08
			c0-2.02,2.11-2.18,3.39-2.39c8.47-1.38,16.98-2.54,25.49-3.73c19.89-2.8,39.78-5.6,59.67-8.37c9.08-1.26,18.19-2.38,27.26-3.72
			c3.01-0.37,4.19,0.67,4.11,3.72c0.06,5.97,0.06,11.13,0.04,17.03c0.21,3.76-1.28,5.19-4.81,5.65c-11.4,1.48-22.44,3.16-34.18,4.61
			c-5.68,0.55-5.25,2.06-5.24,5.99c0.12,34.07,0.07,68.14,0.06,102.21c0,6.46-0.03,6.33-6.39,7.11
			C380.88,1140.07,372.71,1141.31,363.65,1142.58z"/>
					<path className="st0" fill='#fff' d="M1892.23,928.39c-8.84,1.25-17.47,2.47-26.41,3.73c-1.15,0.16-2.19-0.72-2.21-1.89
			c-0.12-12.48-0.28-73.75-0.29-104.23c0-7.49,0.02-7.3-7.19-6.17c-11.21,1.76-22.47,3.22-33.69,4.87
			c-2.78,0.41-3.35-0.77-3.37-3.87c-0.04-5.86-0.04-11.69,0.04-17.62c0.04-3.06,0.26-4.48,3.13-4.87
			c28.83-3.92,102.57-14.26,112.36-15.85c4.33-0.77,4.45,0.71,4.6,5.82c0.14,4.71,0.09,10.9,0.06,16.05
			c-0.02,3.39-0.97,4.23-4.46,4.51c-7.36,0.58-14.72,1.32-22.01,2.53c-18.99,3.17-17.77,0.16-17.77,8.93c0,34.06,0,68.11,0,102.17
			v2.66C1895.03,926.79,1893.83,928.16,1892.23,928.39z"/>
					<path className="st0" fill='#fff' d="M705.92,1094.7V981.07c-14.95,2.07-29.25,4.05-44.14,6.11c0-8.4-0.09-16.22,0.12-24.03
			c0.02-0.88,1.65-2.32,2.68-2.47c21.59-3.12,43.21-6.06,64.82-9.09c15.56-2.18,31.1-4.44,46.66-6.64c1.69-0.24,3.39-0.34,5.71-0.56
			c0,8.28,0.09,16.1-0.13,23.91c-0.02,0.87-1.71,2.3-2.78,2.46c-11.07,1.67-22.18,3.14-33.27,4.66c-1.01,0.14-2.01,0.36-3.03,0.41
			c-3.88,0.17-4.93,2.15-4.92,5.94c0.14,34.19,0.02,68.39,0.16,102.58c0.02,4.18-0.79,6.25-5.48,6.69
			C723.81,1091.82,715.36,1093.34,705.92,1094.7z"/>
				</g>
			</g>
			<g id="a8YMVH.tif_11_">
				<g>
					<path className="st0" fill='#fff' d="M1.9,750.43c0-56.6-0.13-113.21,0.06-169.81c0.09-27.32,10.6-50.12,31.43-68.02
			c10.61-9.12,23.13-13.75,36.79-15.65c29.03-4.04,58.08-7.93,87.11-12.01c34.3-4.82,68.57-9.85,102.87-14.67
			c28.82-4.06,57.67-7.91,86.5-11.95c29.23-4.1,58.44-8.36,87.67-12.48c23.55-3.31,47.11-6.46,70.66-9.77
			c29.43-4.14,58.85-8.4,88.28-12.55c22.94-3.24,45.89-6.37,68.82-9.59c30.04-4.22,60.06-8.53,90.1-12.74
			c23.55-3.3,47.11-6.44,70.66-9.75c29.23-4.1,58.44-8.32,87.66-12.44c28.42-4,56.84-7.92,85.26-11.91
			c29.84-4.19,59.66-8.46,89.5-12.65c28.42-3.99,56.85-7.89,85.27-11.89c29.84-4.19,59.67-8.47,89.51-12.66
			c23.34-3.28,46.7-6.44,70.05-9.71c29.43-4.13,58.85-8.34,88.28-12.48c23.55-3.31,47.1-6.53,70.65-9.83
			c23.95-3.35,47.89-6.77,71.84-10.13c23.75-3.33,47.5-6.61,71.25-9.94c23.75-3.33,47.49-6.72,71.23-10.07
			c23.54-3.31,47.09-6.59,70.64-9.9c24.15-3.4,48.3-6.85,72.46-10.19c10.56-1.46,21.11-3.22,31.73-3.92
			c26.95-1.77,51.61,18.24,56.96,46.03c1.31,6.8,2.23,13.8,2.24,20.71c0.14,112.39,0.17,224.77,0.05,337.16
			c-0.03,29.62-11.21,54.09-34.96,72.48c-12.94,10.02-28.28,12.31-43.77,14.46c-30.46,4.22-60.9,8.55-91.35,12.82
			c-22.53,3.16-45.07,6.29-67.6,9.45c-30.45,4.28-60.88,8.62-91.33,12.87c-23.34,3.26-46.71,6.39-70.05,9.67
			c-29.43,4.13-58.84,8.39-88.27,12.53c-23.34,3.29-46.7,6.46-70.04,9.72c-29.23,4.09-58.45,8.24-87.68,12.34
			c-23.75,3.33-47.5,6.63-71.25,9.96c-23.75,3.33-47.5,6.67-71.25,10c-29.43,4.13-58.87,8.23-88.31,12.36
			c-23.55,3.31-47.09,6.65-70.64,9.95c-23.95,3.36-47.91,6.69-71.86,10.07c-23.95,3.38-47.89,6.83-71.84,10.2
			c-23.14,3.26-46.29,6.45-69.42,9.71c-30.04,4.23-60.06,8.54-90.1,12.74c-23.55,3.3-47.12,6.44-70.66,9.74
			c-29.03,4.07-58.03,8.24-87.06,12.33c-23.54,3.32-47.1,6.57-70.65,9.86c-29.23,4.1-58.46,8.21-87.69,12.32
			c-28.82,4.05-57.65,8.1-86.47,12.16c-29.63,4.17-59.26,8.35-88.9,12.51c-28.83,4.04-57.66,8.04-86.48,12.07
			c-13.19,1.84-26.46,3.28-39.56,5.66c-30.84,5.62-59.92-13.72-65.94-44.68C2.83,933.34,2.02,925.5,2,917.78
			C1.8,861.99,1.89,806.21,1.9,750.43z M539.45,681.65c0.48-0.12,0.95-0.23,1.43-0.35c1.43,1.69,2.93,3.33,4.29,5.07
			c31.59,40.57,63.21,81.11,94.65,121.79c2.89,3.74,5.83,5.09,10.41,4.42c21.87-3.21,43.77-6.26,65.67-9.27
			c4.51-0.62,6.51-2.34,6.5-7.47c-0.17-96.15-0.12-192.3-0.13-288.45c0-7.71-2.1-9.25-9.74-8.14c-24.91,3.61-49.83,7.15-74.78,10.55
			c-8.14,1.11-8.66,1.41-8.66,9.95c-0.01,43.05-0.48,133.97-0.53,136.63c0.08,1.17-1.1,1.5-1.76,0.75c-0.9-1.31-1.74-2.68-2.72-3.94
			c-14.09-18.1-28.21-36.19-42.32-54.28c-17.88-22.94-35.69-45.94-53.78-68.72c-1.57-1.98-5.15-3.81-7.52-3.5
			c-23.14,2.96-46.21,6.47-69.33,9.61c-3.67,0.5-4.95,2.08-4.89,5.54c0.1,6.15,0.01,12.3,0.01,18.45c0,89.39,0,178.77,0,268.16
			c0,11.14,0.88,12.34,12.04,10.88c24.56-3.2,49.01-7.24,73.59-10.24c6.6-0.8,7.7-3.34,7.67-9.22c-0.22-43.67-0.12-87.33-0.12-131
			C539.45,686.47,539.45,684.06,539.45,681.65z M974.11,649.04c0,11.92,0.17,22.54-0.19,33.15c0.05,2.49-2.36,3.67-4.08,4.33
			c-18.69,7.19-37.94,11.7-58.14,10.24c-20.99-1.51-34.5-11.92-40.59-31.71c-5.66-18.39-5.14-37.1-2.06-55.94
			c4.52-27.67,19.65-47.21,45.69-56.9c36.53-13.59,74.38-14.93,112.62-8.68c6.42,1.05,8.66-0.54,8.69-7.17
			c0.08-22.35-0.02-44.69,0.07-67.04c0.02-4.25-1.51-6.1-5.94-6.73c-18.48-2.67-36.9-4.29-55.65-2.98
			c-30.16,2.11-59.82,6.29-88.39,16.58c-40.87,14.71-73.15,39.68-93.71,78.55c-12.36,23.37-17.8,48.57-19.85,74.75
			c-1.83,23.32-0.26,46.39,6.2,68.74c15.44,53.42,55.37,77.41,105.13,80.91c26.77,1.88,53.21-1.18,79.35-6.9
			c29.93-6.55,57.61-18.42,83.08-35.48c4.28-2.87,6.18-6.07,6.15-11.54c-0.25-46.13-0.94-92.27-0.15-138.39
			c0.15-8.73-3.54-9.25-6.48-9.11c-4.54,0.22-11.36,1.32-17.03,2.12c-18.65,2.64-37.29,5.31-55.95,7.93
			c-17.44,2.45-34.89,4.87-52.34,7.25c-3.31,0.45-5.19,1.86-5.17,5.52c0.08,16.2-0.07,32.39,0.1,48.59
			c0.06,5.72,2.65,7.61,8.36,6.78c7.09-1.04,14.15-2.27,21.25-3.24C954.35,651.43,963.66,650.34,974.11,649.04z M1680.34,549.45
			c2.15,2.97,3.78,5.03,5.23,7.22c20.76,31.44,41.51,62.88,62.21,94.36c2,3.05,3.74,5.17,8.27,4.48
			c27.73-4.25,55.51-8.14,83.29-12.07c7.46-1.06,9.05-4.4,4.72-10.67c-34.01-49.18-67.99-98.37-102.14-147.46
			c-2.61-3.75-3.12-6.36-0.17-10.21c22.29-29.11,44.39-58.37,66.53-87.6c9.65-12.74,19.35-25.45,28.81-38.32
			c1.1-1.49,1.78-4.65,0.94-5.86c-1.09-1.58-3.83-2.2-5.97-2.83c-1.08-0.32-2.42,0.23-3.64,0.4c-25.74,3.61-51.51,7.07-77.21,10.95
			c-6.77,1.02-13.67,0.95-18.85,8c-23.63,32.2-47.93,63.91-72.02,95.78c-1.36,1.8-2.96,3.43-5.32,6.15
			c-0.28-3.06-0.52-59.54-0.54-86.4c-0.01-8-1.15-9.37-8.9-8.34c-26.78,3.57-53.52,7.47-80.29,11.15c-4.18,0.58-3.9,3.52-3.9,6.39
			c-0.01,96.17-0.02,192.33,0.01,288.5c0,7.34,1.87,9.21,9.39,8.21c25.57-3.42,51.06-7.41,76.66-10.61
			c5.93-0.74,7.23-2.83,7.17-8.38c-0.28-24.81-0.16-49.62-0.06-74.43c0.01-2.36,0.1-5.25,1.39-6.98
			C1663.62,570.55,1671.68,560.5,1680.34,549.45z M290.62,558.43c-5.26,0.82-10.5,1.72-15.77,2.46
			c-23.12,3.21-46.26,6.24-69.33,9.74c-2.44,0.37-5.65,2.94-6.53,5.24c-6.42,16.78-12.35,33.76-18.42,50.68
			c-16.83,46.93-33.62,93.87-50.47,140.8c-13.54,37.71-27.16,75.39-40.7,113.09c-2.74,7.62,0.15,10.9,8.16,9.67
			c8.9-1.37,17.82-2.63,26.75-3.88c17.64-2.47,35.29-4.91,52.94-7.33c4.25-0.58,6.61-2.58,8.1-6.97
			c6.33-18.55,13.17-36.93,19.81-55.37c1.26-3.51,3.17-5.58,7.41-6.12c23.96-3.07,47.89-6.38,71.78-9.98
			c4.37-0.66,6.11,0.32,7.7,4.38c6.49,16.55,13.48,32.9,20.07,49.41c1.68,4.22,4.05,5.23,8.45,4.41c8.44-1.57,17-2.47,25.51-3.68
			c18.44-2.63,36.9-5.11,55.3-8c7.81-1.23,8.93-3.66,6.04-10.98c-3.45-8.75-7.12-17.41-10.61-26.15
			c-32.83-82.17-65.65-164.33-98.39-246.53C296.89,559.48,294.93,557.43,290.62,558.43z M1516.2,639.23
			c0-11.69,0.07-23.38-0.03-35.07c-0.05-6.4-3.34-8.21-9.09-5.23c-2.18,1.13-4.31,2.36-6.51,3.44
			c-33.39,16.27-67.97,27.14-105.8,23.33c-21.78-2.19-37.68-17.34-41.65-38.9c-3.01-16.34-2.85-32.53-0.07-48.86
			c4.55-26.67,19-45.57,44.32-55.05c35.47-13.27,71.72-13.23,108.18-4.17c8,1.99,10.01,0.55,10.02-7.7
			c0.04-22.15-0.14-44.3,0.14-66.44c0.07-5.27-2.05-7.46-6.81-8.22c-8.9-1.44-19.4-3.48-29.18-4.24
			c-34.72-2.68-68.72,1.79-101.99,11.79c-29.72,8.94-56.29,23.28-77.73,46.24c-22.22,23.78-34.96,52.04-40.41,83.91
			c-5.67,33.17-5.47,66.02,4.73,98.37c5.64,17.89,15.04,33.59,28.8,46.45c23.77,22.22,52.98,30.59,84.52,30.9
			c17.93,0.18,35.97-1.8,53.82-3.96c27.87-3.37,54.11-12.53,79.48-24.39c3.91-1.83,5.45-4.05,5.34-8.41
			C1516.01,661.79,1516.2,650.51,1516.2,639.23z M1196.31,735.11c2.94-0.42,5.14-2.91,5.15-5.88c0.14-31.83,0.34-202.35,0.32-289.86
			c0-7.03-1.44-8.65-8.34-7.73c-25.36,3.38-50.7,6.92-76,10.67c-8.88,1.31-8.83,1.67-8.83,10.55c0,21.12,0,42.23,0,63.35
			c0,72.98,0,145.96,0,218.94c0,5.83,0.93,11.96,11.1,10.63C1145.04,742.48,1170.31,738.78,1196.31,735.11z"/>
					<path className="st0" fill='#fff' d="M249.34,665.82c8.61,21.69,17.23,43.38,26.22,66.01c-18.02,2.58-35.21,5.03-54.1,7.73
			c9.12-25.6,17.68-49.64,26.24-73.67C248.24,665.87,248.79,665.85,249.34,665.82z"/>
				</g>
			</g>
			<path className="st0" fill='#fff' d="M1173.53,153.94c0,0-271.02,27.23-327.22,31.24c-42.77,3.05-153.2,11.08-258.9,14.89
	c29.7-34.58,101.88-107.73,218.64-136.48c65.66-16.17,122.54-12.65,159.87-7.02c1.5,0.23-86.06-20.67-187.08,6.73
	c-76.76,20.82-129.81,59.75-151.75,77.37c-26.71,21.45-46.67,42.94-60.8,60.11c-25.88,0.79-51.18,1.29-74.8,1.36
	c-125.6,0.34-169.14-18.54-174.66-22.74c-5.52-4.21-1.62-11.94-1.62-11.94c110.44-51.97,190.3-82.2,237.91-98.86
	c26.39-9.23,44.92-15.03,58.12-18.95c78.01-23.14,139.53-32.93,158.25-36.01C826.43,4.26,936.41-3.92,988.97,2.01
	c-4.18,6.03-11.47,19.27-11.8,35.54c-0.1,4.71-0.17,12.91,4.08,21.74c0,0,4.44,9.21,14.19,16.49
	C1009.57,86.33,1078.28,116.89,1173.53,153.94z M1719.98,176.36c15.8,9.58,39.47,22.26,72.13,31.64l77.85-11.32
	c-19.03-8.66-39.89-17.31-57.98-24.41c-34.21-13.42-63.5-17.78-63.5-17.78s-34.04-19-48.6-23.97c-7.83-2.67-20.49-4.55-32.58-5.95
	c1.04,2.12,2.22,4.37,3.63,6.78C1677.43,142.43,1689.09,157.64,1719.98,176.36z M11.05,441.57c-3.47-14.21-10.15-28.82-9.08-50.04
	c0.37-7.37,2.88-18.11,13.5-30.88c28.18,3.01,52.25,3.94,70.75,4.12c32.33,0.31,51.1-0.43,72.18-3.05
	c29.37-3.65,41.32-6.63,53.06-13.14c13.82-7.66,23.07-17.54,28.72-24.73c-32.15,2.84-59.71,3.78-81.25,3.98
	c-52.81,0.49-95.2-3.23-117.94-5.28c-2.92-0.26-5.45-0.5-7.58-0.71c1.38-1.4,2.68-2.36,2.68-2.36s-1.36-6.33,4.48-11.23
	c6.21-5.15,31.13-22.24,47.66-33c12.89-8.39,33.14-19.14,38.87-21.01c4.36-1.42,5.28-1.37,5.28-1.37s0.03-0.05,0.04-0.08
	c1.49,2.04,3.12,4.24,4.92,6.6c2.77,3.65,4.48,5.77,7.46,6.93c2.11,0.82,4.21,0.9,6.27,0.67c18.68-2.13,69.18-34.58,75.94-38.81
	c0.55-0.34,1.66-1.03,2.43-2.38c3.29-5.72-3.64-15.9-10.33-23.81c13.81-7.66,59.51-28.45,67.63-30.76
	c14.99-4.27,21.94-2.97,21.94-2.97s-12.77,11.62,11.86,22.63c24.63,11.01,98.73,20.57,195.83,19.92
	c97.1-0.65,173.18-5.08,219.06-8.05c122.47-4.98,422.89-37.55,470.12-45.13c0,0-177.97-80.5-199.3-91.04
	c-21.33-10.53-14.55-35.75-12.99-41.28c1.67-5.95,5.24-15.77,7.41-20.44c0.85,0.26,11.55,2.55,27.77,7.99
	c-4.13,4.66-7.37,11.02-8.65,14.56c-1.77,4.89-1.58,7.63-1.02,9.59c1.39,4.87,6.23,7.44,8.34,8.53c9.14,4.7,85.29,42.34,189.9,93.61
	c-42.81-22.72-85.66-45.4-128.52-68.03c-18.62-9.83-39.31-20.75-57.94-30.56c-1.11-0.56-4.05-2.25-5.13-5.55
	c-0.69-2.12-0.39-4.22,0.57-6.89c1.88-5.25,5.22-9.78,8.19-13.13c29.31,8.94,73.6,22.64,115.33,36.38
	c70.72,23.29,163.9,62.04,163.9,62.04s93.28-1.52,133.07-5.05c39.79-3.53,120.76-7.09,155.2,1.2c34.45,8.3,25.99,10.83,39.15,12.29
	c2.87,0.32,6.65,0.68,10.89,1.09c1.76,3.72,4.11,8.11,7.23,12.8c4.44,6.68,14.63,20.29,38.64,35.05
	c32.95,20.25,75.1,32.3,94.5,37.21l-140.17,20.39c-30.05-24.55-64.72-36.24-101.5-35.98c-50.4,0.34-96.64,17.71-134.38,60.31
	c-3.26,3.68-6.23,7.45-8.98,11.29L594.73,382.17c-1.18-1.52-57.47-91.3-192.37-59.81c-84.7,19.78-109.34,83.17-120.37,105.3
	L4.67,467.99c-0.23-2.49-0.17-4.73,0.34-6.21C6.67,456.94,13.13,450.07,11.05,441.57z"/>
		</svg>

	)
}
