import React from 'react'
import { useSelector } from 'react-redux'
import Select from "react-select"
import s from "./select.module.sass"

const SelectOther = ({
    children,
    styles = {
        control: (provided) => ({
            ...provided,
            width: "100%",
            height: "4.4rem",
            borderRadius: 5,
            border: ".1rem solid var(--InputColorWhite)",
            fontSize: "1.4rem",
            color: "var(--PropertyTextColorWhite)",
            paddingLeft: "1.5rem",
            background: "var(--SecondaryColorDark)",
            transition: ".3s"
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: "1.4rem",
            background: "var(--SecondaryColorDark)",
            border: ".1rem solid var(--InputColorWhite)",
            color: "var(--PropertyTextColorWhite)",
        }),
        input: (provided) => ({
            ...provided,
            color: "var(--PropertyTextColorWhite)",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "var(--PropertyTextColorWhite)"
        })
    },
    options,
    value,
    ...props
}) => {

    const THEME = useSelector(state => state.theme)

    return (
        <label htmlFor="select" className={s.label}>
            <span className={s["label-text"]}>{children}</span>
            <Select
                {...props}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: THEME.theme === "dark" ? '#80838B' : "#1E7FF0",
                    },
                })}
                value={value}
                options={options}
                styles={styles}
                placeholder="Select"
                id="select"
            />
        </label>
    )
}

export default SelectOther