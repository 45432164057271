import React from 'react'
import { useSelector } from 'react-redux'

export default function Moon({ ...props }) {

    let color = "#DCE2F9"

    const THEME = useSelector(state => state.theme)

    if (THEME.theme === "dark") color = "#1E7FF0"

    return (
        <svg {...props} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1285_1952)">
                <path d="M11.1158 0.16965C10.929 0.343318 10.8648 0.6105 10.9524 0.850185C11.2635 1.70179 11.4214 2.60165 11.4214 3.52485C11.4214 5.62573 10.6032 7.60106 9.11776 9.08703C7.63204 10.5733 5.65619 11.3917 3.55453 11.3917C2.63353 11.3917 1.73483 11.2339 0.883352 10.9227C0.643796 10.8351 0.376743 10.8992 0.202945 11.0861C0.029147 11.273 -0.0155995 11.5442 0.0891982 11.777C0.769605 13.2899 1.86025 14.5794 3.24299 15.5059C4.66009 16.4553 6.31364 16.9715 8.02516 16.9988C9.24253 17.0183 10.4273 16.7924 11.5467 16.3274C12.6251 15.8794 13.5937 15.231 14.4255 14.4004C15.2571 13.5698 15.9067 12.6023 16.3562 11.5245C16.8056 10.4465 17.0332 9.30752 17.0331 8.13685C17.0331 8.09275 17.0328 8.04839 17.0322 8.00416C17.0072 6.2907 16.4912 4.6343 15.5404 3.21435C14.613 1.82955 13.3219 0.737346 11.8067 0.0560331C11.5739 -0.0487643 11.3027 -0.00414757 11.1158 0.16965V0.16965Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_1285_1952">
                    <rect width="17" height="17" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
