import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Map from "../../assets/images/Map"
import s from "./background.module.sass"

const Background = () => {

    const { theme } = useSelector(state => state.theme)

    const location = useLocation()

    let className

    if(location.pathname === "/") className = s.bg__image
    if(location.pathname === "/home/2") className = s.bg__image__car
    if(location.pathname === "/home/3") className = s.bg__image__calendar

    return (
        <div className={className}>
            {/* <Map className={s.image}
                style={{
                    pointerEvents: "none",
                    userSelect: "none",
                    zIndex: 0,
                    width: "98.2%",
                    objectFit: "cover",
                    transition: ".3s"
                }}
            /> */}
            {
                theme === "white" ? (location.pathname === "/" && <img src="/images/Map.svg" alt="" className={s.image}
                />) : (location.pathname === "/" && <img src="/images/MapDark.svg" alt="" className={s.image} />)
            }
            {
                theme === "white" ? (location.pathname === "/home/2" && <img src="/images/car_white.png" alt="" className={s.image__car} />) : (location.pathname === "/home/2" && <img src="/images/car.png" alt="" className={s.image__car} />)
            }
            {
                theme === "white" ? (location.pathname === "/home/3" && <img src="/images/calendar_white.svg" alt="" className={s.image__calendar} />) : (location.pathname === "/home/3" && <img src="/images/calendar.svg" alt="" className={s.image__calendar} />)
            }
        </div>
    )
}

export default Background