import React from 'react'
import OtherPagesLayout from "../../components/OtherPagesLayout/OtherPagesLayout"
import s from "./our-services.module.sass"

const OurServices = () => {
    return (
        <OtherPagesLayout>
            <div className={s["our-services"]}>
                <h1 className={s.title}>Our services</h1>
                <h2 className={s.subtitle}>We represent only high-quality transportation</h2>
                <p className={s["p-title"]}>
                    Angick Inc is dedicated to providing high quality services to all
                    type of vehicles. Get in touch with any additional questions.
                </p>
                <div>
                    <section className={s.right__template}>
                        <div className={s.text}>
                            <h3>Always Prepared</h3>
                            <p>
                                Since its founding, Angick Inc has been one of the most trusted
                                names in the industry. Hire us for this service and learn how we cater
                                to the needs of each client, ensuring the results you need and
                                deserve.
                            </p>
                        </div>
                        <div className={s.image__pattern}>
                            <img src="/pattern/pic 1.png" alt="First picture" />
                            <img src="/pattern/shadow.svg" alt="Shadow" />
                            <img src="/pattern/dotPattern.svg" alt="Dot pattern" />
                        </div>
                    </section>
                    <section className={s.center__template}>
                        <div className={s.image__pattern}>
                            <img src="/pattern/pic 2.png" alt="First picture" />
                            <img src="/pattern/dotPattern.svg" alt="Dot pattern" />
                        </div>
                        <div className={s.text}>
                            <h3>Attention to Detail</h3>
                            <p>
                                We have the experience and skills necessary to tackle just about
                                every type of job that comes our way. With Angick Inc, clients know
                                exactly what to expect - professionalism, efficiency and exceptional
                                results.
                            </p>
                        </div>
                    </section>
                    <section className={s.right__template}>
                        <div className={s.text}>
                            <h3>Expert Service</h3>
                            <p>
                                Angick Inc is committed to getting the job done, especially when it
                                comes to this service. You can count on us to be professional, timely,
                                efficient and make sure you’re satisfied every step of the way.
                            </p>
                        </div>
                        <div className={s.image__pattern}>
                            <img src="/pattern/pic 3.png" alt="First picture" />
                            <img src="/pattern/shadow.svg" alt="Shadow" />
                            <img src="/pattern/dotPattern.svg" alt="Dot pattern" />
                        </div>
                    </section>
                </div>
            </div>
        </OtherPagesLayout>
    )
}

export default OurServices