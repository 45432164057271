import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CalculateCostLayoutSteps from '../../../components/CalculateCostLayoutSteps/CalculateCostLayoutSteps'
import Button from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/Input/Input'
import isValidCardCVV from "../../../utils/isValidCardCVV"
import isValidCardDate from "../../../utils/isValidCardDate"
import isValidCardNumber from "../../../utils/isValidCardNumber"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import s from "./book-shipment.module.sass"

const BookShipment = () => {

    const navigate = useNavigate()

    const ORDER = useSelector(state => state.order)
    const { order } = ORDER
    const { theme } = useSelector(state => state.theme)

    let discountedPrice, regularPrice

    if (order.address.origin) {
        regularPrice = order.price + (order.open ? order.trailer_price.open : order.trailer_price.enclosed) + (order.running ? order.condition_price.running : order.condition_price.not_running)
        discountedPrice = Math.floor((regularPrice - (regularPrice * order.discount.percent / 100)) * (order.discount.paid_part / 100))
    }

    const [bankCard, setBankCard] = useState({
        number: "",
        holder: "",
        expiration: "",
        security_code: ""
    })

    const [cardAddress, setCardAddress] = useState({
        address: "",
        city: "",
        state: "",
        zip_code: 0,
    })

    const nextStep = async () => {

        if (isValidCardDate(bankCard.expiration) && isValidCardNumber(bankCard.number) && bankCard.holder.includes(" ")) {

            const createPickup = () => {
                const pickup = {
                    "address": order.pickup.address,
                    "additionally": order.pickup.additionally,
                    "business_name": order.pickup.business_name,
                }
                const foo = {
                    contact: {
                        "full_name": order.delivery.contact.full_name,
                        "phones": order.delivery.contact.phones
                    }
                }
                const { contact } = foo
                if (!contact.phones.length) delete contact["phones"]
                return (contact.full_name ? Object.assign(pickup, foo) : pickup)
            }
            const createDelivery = () => {
                const delivery = {
                    "address": order.delivery.address,
                    "additionally": order.delivery.additionally,
                    "business_name": order.delivery.business_name,
                }
                const foo = {
                    contact: {
                        "full_name": order.delivery.contact.full_name,
                        "phones": order.delivery.contact.phones
                    }
                }
                const { contact } = foo
                if (!contact.phones.length) delete contact["phones"]
                return (contact.full_name ? Object.assign(delivery, foo) : delivery)
            }

            const orderBook = {
                details: {
                    discounted: true,
                    vehicle_operable: order.running,
                    open_trailer: order.open,
                },
                information: {
                    email: order.email,
                    full_name: order.full_name,
                    phones: order.phones.filter((phone) => phone),
                },
                payment: {
                    card: bankCard,
                    billing: cardAddress
                },
                pickup: createPickup(),
                delivery: createDelivery(),
                order_id: order.id
            }

            try {
                const response = await axios.post(`https://apiv2.angick.com/order/complete`, orderBook, {
                    validateStatus: () => true
                })
                if (response.data.success) {
                    navigate(`/calculate-cost/${order.id}/thank-you`)
                } else {
                    response.data.messages.forEach(message => toast.error(message, {
                        
                    }))
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            toast.error("Something went wrong with input data, please check it and try again.", { autoClose: 7000 })
        }
    }

    return (
        <CalculateCostLayoutSteps>
            <form action="" className={s.book__shipment} onSubmit={event => event.preventDefault()}>
                <div className={s.left__side}>
                    <h1 className={s.title}>Book shipment</h1>
                    <Input
                        label="Card number"
                        placeholder="---- ---- ---- ----"
                        isLock={true}
                        mask="9999 9999 9999 9999"
                        maskChar=" "
                        onChange={(event) => setBankCard({ ...bankCard, number: event.target.value.replaceAll(" ", "") })}
                        onClick={(event) => event.target.style.border = ".1rem solid var(--InputColorWhite)"}
                    />
                    <Input
                        label="Full name on card"
                        placeholder="John Doe"
                        onChange={(event) => setBankCard({ ...bankCard, holder: event.target.value })}
                        onClick={(event) => event.target.style.border = ".1rem solid var(--InputColorWhite)"}
                    />
                    <Input
                        label="Expiration data"
                        placeholder="MM/YYYY"
                        mask="99 / 9999"
                        maskChar="-"
                        onChange={(event) => {
                            const nonParsedDate = event.target.value
                            const parsedDate = nonParsedDate.match(/^(0[1-9]|1[0-2])\s?\/?\s?([0-9]{4}|[0-9]{2})$/)
                            if (parsedDate) setBankCard({ ...bankCard, expiration: `${parsedDate[2]}-${parsedDate[1]}` })
                        }}
                        onClick={(event) => event.target.style.border = ".1rem solid var(--InputColorWhite)"}
                    />
                    <Input
                        label="Security code"
                        placeholder="CVV"
                        // type="password"
                        mask="9999"
                        maskChar=""
                        onChange={(event) => {
                            event.preventDefault()
                            setBankCard({ ...bankCard, security_code: event.target.value })
                        }}
                        onClick={(event) => event.target.style.border = ".1rem solid var(--InputColorWhite)"}
                    />
                    <div className={s.agreement}>
                        By clicking "Book shipment", <br /> 
                        you agree to our <a href="/terms-and-conditions.pdf" target="_blank">Terms & Conditions</a>
                    </div>
                    <Button
                        width="15.9rem"
                        height="4.4rem"
                        onClick={nextStep}
                        disabled={isValidCardDate(bankCard.expiration) && isValidCardNumber(bankCard.number) && bankCard.holder.includes(" ") && cardAddress.address && cardAddress.city && cardAddress.state && (String(cardAddress.zip_code).length === 5) ? false : true}
                    >
                        BOOK SHIPMENT
                    </Button>
                </div>
                <div className={s.right__side}>
                    <div className={s.due__now}>
                        <h1>${order.discounted ? discountedPrice : regularPrice} Due now</h1>
                        {ORDER.order.discounted ?
                            <p>
                                Partial payment will be charged, the remaining  <br />
                                balance will be due in COD (cash on delivery).
                            </p> :
                            <p>
                                One-time payment in full by using your credit <br />
                                card or debit card will be charged.
                            </p>
                        }
                    </div>
                    <div className={s.lock__info}>
                        <img src="/images/icons/lock-big.svg" alt="Lock" />
                        <span>This is a secure 256-bit SSL Encrypted site. You’re safe! </span>
                    </div>
                    <h1 className={s.title}>Billing address</h1>
                    <div className={s.grid}>
                        <Input 
                            label="Address"
                            placeholder="Address"
                            onChange={(event) => setCardAddress({ ...cardAddress, address: event.target.value })}
                        />
                        <Input 
                            label="City"
                            placeholder="City"
                            onChange={(event) => setCardAddress({ ...cardAddress, city: event.target.value })}
                        />
                        <Input
                            label="State"
                            placeholder="State"
                            onChange={(event) => setCardAddress({ ...cardAddress, state: event.target.value })}
                        />
                        <Input
                            label="ZIP code"
                            placeholder="ZIP code"
                            mask="99999"
                            onChange={(event) => setCardAddress({ ...cardAddress, zip_code: parseInt(event.target.value) || 0 })}
                        />
                    </div>
                </div>
            </form>
            <ToastContainer
                theme={theme === "white" ? "light" : "dark"}
                toastStyle={{ fontSize: "1.2rem", background: "var(--SecondaryColorDark)" }}
            />
        </CalculateCostLayoutSteps>
    )
}

export default BookShipment