import React from 'react'
import OtherPagesLayout from "../../components/OtherPagesLayout/OtherPagesLayout"
import s from "./how-does-it-work.module.sass"

const HowDoesItWork = () => {
    return (
        <OtherPagesLayout>
            <div className={s.main}>
                <h1>How does it work</h1>
                <div className={s.video}>
                    <iframe type="text/html"
                        title="Angick"
                        height="570"
                        src="https://www.youtube.com/embed/AmU8MJwUsLg"
                        frameBorder="0">
                    </iframe>
                </div>
                <div className={s.blocks}>
                    <div className={s.block__template}>
                        <div>
                            <img src="/images/icons/laptop.svg" alt="" />
                            <h4 style={{ color: "var(--Blue)" }}>Step 1</h4>
                        </div>
                        <h2>
                            Quote and book
                            your order
                        </h2>
                        <p>
                            Fill out the form on the website,
                            find out the cost of delivery and
                            place an order.
                        </p>
                    </div>
                    <img src="/images/CurveFirst.svg" alt="CurveFirst" />
                    <div className={s.block__template}>
                        <div>
                            <img src="/images/icons/car.svg" alt="" />
                            <h4 style={{ color: "#F0B51E" }}>Step 2</h4>
                        </div>
                        <h2>
                            We pick up
                            your vehicle
                        </h2>
                        <p>
                            After the registration and
                            processing of the application, we
                            will pick up your car at the
                            appointed time.
                        </p>
                    </div>
                    <img src="/images/CurveSecond.svg" alt="CurveSecond" />
                    <div className={s.block__template}>
                        <div>
                            <img src="/images/icons/list.svg" alt="" />
                            <h4 style={{ color: "#F01E5D" }}>Step 3</h4>
                        </div>
                        <h2>
                            Receive your
                            vehicle
                        </h2>
                        <p>
                            Get the car safely and safely 
                            in the specified time frame.
                        </p>
                    </div>
                </div>
            </div>
        </OtherPagesLayout>

    )
}

export default HowDoesItWork