import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CalculateCostLayout from '../../../components/CalculateCostLayout/CalculateCostLayout'
import Button from '../../../components/UI/Button/Button'
import { setOrder } from '../../../redux/reducers/mainReducer'
import s from "./choose.module.sass"

const Choose = () => {

    const navigate = useNavigate()

    const ORDER = useSelector(state => state.order)
    const { order } = ORDER

    const dispatch = useDispatch()

    let discountedPrice, regularPrice

    if (order.address.origin) {
        regularPrice = order.raw_price + (order.open ? order.trailer_price.open : order.trailer_price.enclosed) + (order.running ? order.condition_price.running : order.condition_price.not_running)
        if(regularPrice < order.min_price) {
            console.log(true);
            regularPrice = order.min_price
        }
        discountedPrice = Math.floor((regularPrice - (regularPrice * order.discount.percent / 100)))
    }

    const clickFirst = (event) => {
        event.target.style.borderColor = "#3caf55"
        document.querySelector(".second-block").style.borderColor = "var(--StrokeBlockWhite)"
        dispatch(setOrder({ ...order, discounted: true }))
    }

    const clickSecond = (event) => {
        event.target.style.borderColor = "#3caf55"
        document.querySelector(".first-block").style.borderColor = "var(--StrokeBlockWhite)"
        dispatch(setOrder({ ...order, discounted: false }))
    }

    const firstChoose = () => {
        const firstBlock = document.querySelector("#choose-one")
        const secondBlock = document.querySelector("#choose-two")
        firstBlock.style.borderColor = "#3caf55"
        secondBlock.style.borderColor = "var(--StrokeBlockWhite)"
    }

    const secondChoose = () => {
        const firstBlock = document.querySelector("#choose-one")
        const secondBlock = document.querySelector("#choose-two")
        secondBlock.style.borderColor = "#3caf55"
        firstBlock.style.borderColor = "var(--StrokeBlockWhite)"
    }

    // console.log(order);

    return (
        <CalculateCostLayout>
            <div className={s.choose}>
                <div className={s.blocks}>
                    <div id="choose-one" onClick={(event) => clickFirst(event)} className={`${s.template} first-block`}>
                        <h3 onClick={ () => firstChoose() }>Discounted price <img src="/images/icons/party-icon.svg" alt="" /></h3>
                        <h1 onClick={ () => firstChoose() }>${discountedPrice}</h1>
                        <p onClick={ () => firstChoose() }>
                            Partial payment will be charged, the remaining
                            balance will be due in COD (cash on delivery).
                        </p>
                    </div>
                    <div id="choose-two" onClick={(event) => clickSecond(event)} className={`${s.template} second-block`}>
                        <h3 onClick={ () => secondChoose() }>Regular price</h3>
                        <h1 onClick={ () => secondChoose() }>${regularPrice}</h1>
                        <p onClick={ () => secondChoose() }>
                            One-time payment in full by using your credit
                            card or debit card will be charged.
                        </p>
                    </div>
                </div>
                <section className={s.first__tip}>
                    <h1>Don't know the exact day?</h1>
                    <p>
                        Don’t worry! You can change at any time. You can call one of our friendly Customer Service Agents anytime! <a href="tel:+1(313)509-8987">+1(313)509-8987</a>
                    </p>
                </section>
                <Button width="25.9rem" onClick={() => {
                    if (order.discounted === "") {
                        document.querySelector(".second-block").style.borderColor = "red"
                        document.querySelector(".first-block").style.borderColor = "red"
                    } else {
                        navigate(`/calculate-cost/${order.id}/your-info`)
                        dispatch(setOrder({ ...order, discountedPrice, regularPrice }))
                    }

                }}>
                    CONTINUE TO BOOKING DETAILS
                </Button>
                <div className={s.second__tip}>
                    <span>Your quote has been e-mailed to you.</span>
                </div>
            </div>
        </CalculateCostLayout>
    )
}

export default Choose