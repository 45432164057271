import React from 'react'
import Background from '../Background/Background'
import Text from '../Text/Text'
import s from "./layout.module.sass"

const Layout = ({ children }) => {
    return (
        <main className={s.layout}>
            <div className={s.main}>
                <Text />
                {children}
            </div>
        </main>
    )
}

export default Layout