import React from 'react'
import s from "./textarea.module.sass"

const Textarea = ({ value, setValue, placeholder = "Type your message here...", style = {} }) => {
    return (
        <textarea
            className={s.textarea}
            style={style}
            cols="30"
            rows="10"
            spellCheck={false}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={event => setValue(event.target.value)}
        >
        </textarea>
    )
}

export default Textarea