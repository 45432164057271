import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRoutes from './components/AppRoutes'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import "./styles/App.sass"

const App = () => {

    const THEME = useSelector(state => state.theme)

    return (
        <Router>
            <div
                className={THEME.theme === "white" ? "App" : "App--dark"}
            >
                <Header />
                <AppRoutes />
                <Footer />
            </div>
        </Router>
    )
}

export default App