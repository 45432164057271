import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    order: {
        address: {
            origin: "",
            destination: "",
        },
        open: true,
        vehicle: {
            year: 0,
            make_id: "",
            model_id: ""
        },
        running: true,
        email: "",
        phone: 0,
        phones: [0],
        discounted: "",
        vehicle_modify: false
    }
}

export const mainReducer = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrder: (state, action) => {
            state.order = action.payload
        }
    },
})

export const { setOrder } = mainReducer.actions
export default mainReducer.reducer