import React, { useState, useRef } from 'react'
import OtherPagesLayout from "../../components/OtherPagesLayout/OtherPagesLayout"
import Input from "../../components/UI/Input/Input"
import Button from "../../components/UI/Button/Button"
import ReCAPTCHA from 'react-google-recaptcha'
import { parsePhone } from "../../utils/parsePhone"
import s from "./contact-us.module.sass"
import axios from 'axios'
import { validateEmail } from '../../utils/validateEmail'
import { validatePhone } from '../../utils/validatePhone'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Textarea from '../../components/UI/Textarea/Textarea'
import { useSelector } from 'react-redux'

const ContactUs = () => {

    const recaptchaRef = useRef()

    const { theme } = useSelector(state => state.theme)

    const form = useRef()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [captcha, setCaptcha] = useState("")

    const onChange = (value) => setCaptcha(value)

    const sendPost = async () => {
        try {
            const res = await axios.post("https://apiv2.angick.com/contact_us", {
                name,
                email,
                phone,
                subject,
                message,
                g_recaptcha_response: captcha
            }, {
                validateStatus: () => true
            })
            return res
        } catch (error) {
            console.log(error)
        }
    }

    try {
        return (
            <OtherPagesLayout>
                <div className={s["contact-us"]}>
                    <h1>Contact us</h1>
                    <div className={s.main}>
                        <div className={s.image}>
                            <h2>
                                Any questions,
                                suggestions,
                                comments?
                            </h2>
                            <div className={s.something}>
                                <img src="/images/mail.svg" alt="" />
                                <img src="/images/arrow.svg" alt="" />
                            </div>
                        </div>
                        <form ref={form} onSubmit={event => event.preventDefault()}>
                            <h4>Get in touch</h4>
                            <div className={s.inputs}>
                                <div>
                                    <Input
                                        value={name}
                                        width={305}
                                        placeholder="Name"
                                        onChange={event => setName(event.target.value)}
                                    />
                                    <Input
                                        width={305}
                                        placeholder="Email"
                                        type="email"
                                        value={email}
                                        onChange={event => setEmail(event.target.value)}
                                        label="contact-us-email"
                                        isLabelShow={false}
                                    />
                                    <Input
                                        width={305}
                                        placeholder="Phone"
                                        value={phone}
                                        mask="(999)999-9999"
                                        maskChar="_"
                                        onChange={event => setPhone(parsePhone(event.target.value))}
                                        label="contact-us-phone"
                                        isLabelShow={false}
                                    />
                                    <Input
                                        value={subject}
                                        width={305}
                                        placeholder="Subject"
                                        onChange={event => setSubject(event.target.value)}
                                    />
                                </div>
                                <div>
                                    <Textarea value={message} setValue={setMessage} />
                                    {theme === "white" ?
                                        <ReCAPTCHA
                                            theme={"light"}
                                            ref={recaptchaRef}
                                            sitekey="6LcNOsAfAAAAANUOoT15FbuhrUjfgZw2OHcLW2yp"
                                            onChange={event => onChange(event)}
                                            className="captcha"
                                        /> :
                                        <ReCAPTCHA
                                            theme={"dark"}
                                            ref={recaptchaRef}
                                            sitekey="6LcNOsAfAAAAANUOoT15FbuhrUjfgZw2OHcLW2yp"
                                            onChange={event => onChange(event)}
                                            className="captcha"
                                        />
                                    }
                                </div>
                            </div>
                            <Button
                                disabled={name && email && phone && subject && message && captcha ? false : true}
                                width={305}
                                onClick={async () => {
                                    if (validateEmail(email, "#contact-us-email") && validatePhone(phone, "#contact-us-phone") && captcha) {
                                        const data = await sendPost()
                                        if (data.status !== 200) toast.error("Something went wrong. Please, try later.", { autoClose: 7000 })
                                        else toast.success("Thank you for your message. We will response to you as soon as possible.", { autoClose: 7000 })
                                        form.current.reset()
                                    } else {
                                        console.log("validate fail")
                                    }
                                }}>
                                Send your question
                            </Button>
                        </form>
                    </div>
                </div>
                <ToastContainer
                    theme={theme === "white" ? "light" : "dark"}
                    toastStyle={{ fontSize: "1.4rem", background: "var(--SecondaryColorDark)" }}
                />
            </OtherPagesLayout>
        )
    } catch (error) {
        toast.error("Something went wrong. Please, try later.", { autoClose: 7000 })
    }
}

export default ContactUs