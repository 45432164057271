import React from 'react'
import Details from '../Details/Details'
import s from "./calculate-cost.layout.module.sass"

const CalculateCostLayout = ({ children }) => {
    return (
        <div className={s.layout}>
            <Details />
            {children}
        </div>
    )
}

export default CalculateCostLayout