import React from 'react'
import s from "./other-pages.layout.module.sass"

const OtherPagesLayout = ({ children }) => {
    return (
        <div className={s.layout}>
            {children}
        </div>
    )
}

export default OtherPagesLayout