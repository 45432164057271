import React from 'react'
import ReactTooltip from "react-tooltip"
import s from "./tooltip.module.sass"


const Tooltip = ({ text, tooltipBody, whiteSpace = "pre-line", fontSize, width, style, ...props }) => {

    return (
        <div className={s.tooltip} >
            <p style={{ whiteSpace, fontSize }} className={s.text}>{text}</p> <span data-tip={tooltipBody} data-for="rc-tip" className={s.svg}><img src="/images/icons/question.svg" alt="Question" /></span>
            {/* <span  className={s.tip} style={{ width, ...style }} >{tooltipBody}</span> */}
        </div >
    )
}

export default Tooltip