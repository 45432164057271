import axios from "axios"

export const getYears = async () => {

    const response = await axios.get("https://apiv2.angick.com/makes/years")

    const vechileYears = []

    for (let i = response.data.years.max; i >= response.data.years.min; i--) {
        vechileYears.push({ value: i, label: i })
    }
    return vechileYears
}