import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { setOrder } from '../../redux/reducers/mainReducer'
import Checkbox from '../UI/Checkbox/Checkbox'
import Tooltip from '../UI/Tooltip/Tooltip'
import s from "./details.module.sass"
import axios from "axios"
import ReactTooltipCustomized from '../ReactTooltipCustomized/ReactTooltipCustomized'

const Details = () => {

    const THEME = useSelector(state => state.theme)

    const ORDER = useSelector(state => state.order)
    const { order } = ORDER

    const params = useParams()

    const dispatch = useDispatch()

    let discountedPrice, regularPrice

    if (order.address.origin) {
        regularPrice = order.raw_price + (order.open ? order.trailer_price.open : order.trailer_price.enclosed) + (order.running ? order.condition_price.running : order.condition_price.not_running)
        if(regularPrice < order.min_price) regularPrice = order.min_price
        discountedPrice = Math.floor((regularPrice - (regularPrice * order.discount.percent / 100)) * (order.discount.paid_part / 100))
    }

    useEffect(() => {
        if (!order.email) {
            dispatch(setOrder({ ...order, open: true, running: true }))
            try {
                (async () => {
                    const response = await axios.get(`https://apiv2.angick.com/order/${params.id}`)
                    const data = response.data.order
                    const fullPrice = data.price + (order.open ? data.trailer_price.open : data.trailer_price.enclosed) + (order.running ? data.condition_price.running : data.condition_price.not_running)
                    console.log(fullPrice);
                    dispatch(setOrder({
                        ...order, ...data, vehicle: {
                            year: data.model.year,
                            make: data.model.make_name,
                            model: data.model.name,
                        },
                        ...data.information,
                        discounted: false,
                        transit_time: [data.transit_time.min, data.transit_time.max],
                        price: fullPrice < data.min_price ? data.min_price : fullPrice,
                        raw_price: data.price,
                        min_price: data.min_price
                    }))
                })()
            } catch (error) {
                console.log(error);
            }
        }
    }, [])

    const car = `${order.vehicle.year} ${order.vehicle.make} ${order.vehicle.model}`

    return (
        <div className={s.details} style={{ backgroundImage: THEME.theme === "white" ? `url("/images/DetailsBg.svg")` : `url("/images/DetailsBgDark.svg")` }}>
            <h1>Details</h1>
            <div className={s.body}>
                <ul>
                    <li>Ship FROM</li>
                    <li>Ship TO</li>
                    <li>Distance</li>
                    <li><Tooltip text="Transit time" whiteSpace="nowrap" tooltipBody="Transit time is calculated from the time the vehicle is picked up" /></li>
                    <li>Vehicle</li>
                    <li><Tooltip text="Insurance" tooltipBody="Your vehicle is fully covered from pickup to delivery" /></li>
                    <li>
                        <Tooltip
                            text={`Vehicle
                            condition`}
                            tooltipBody={`Vehicle runs and drives`}
                        />
                    </li>
                    <li>
                        <Tooltip
                            text={`Transport
                            type`}
                            tooltipBody={`Open trailer. <br/>
                    The most common type of trailer is an open multi-car hauler. <br/>
                    It takes up to 9 cars on a single trailer. <br/>
                    When transporting a car on an open trailer, <br/>
                    you should consider the fact that the car is exposed to various weather conditions such as sun, rain, winds, etc during the auto shipping. <br/>
                    Enclosed trailer. <br/>
                    Enclosed trailers are the safer option and of course are  more expensive than the open trailers. <br/>
                    Expensive cars with low ground clearance are strongly advised to be shipped with an enclosed trailer with lift <br/>gate or low ramps.`}
                        />
                    </li>
                </ul>
                <ul>
                    <li>{order.address.origin}</li>
                    <li>{order.address.destination}</li>
                    <li>{order.miles} miles</li>
                    <li>{order?.transit_time?.length ? `${order.transit_time[0]}-${order.transit_time[1]}` : "4-6"} days</li>
                    <li>{car.length > 18 ? car.substring(0, 21) + "..." : car}</li>
                    <li>Included</li>
                    <li>
                        <div>
                            <Checkbox
                                checked={order.running}
                                text="Running"
                                type="radio"
                                name="condition-cc"
                                // defaultChecked={true}
                                onChange={() => dispatch(setOrder({ ...order, running: true }))}
                            />
                            <Checkbox
                                checked={!order.running}
                                text="Not-running"
                                type="radio"
                                name="condition-cc"
                                onChange={() => dispatch(setOrder({ ...order, running: false }))}
                            />
                        </div>
                    </li>
                    <li>
                        <div>
                            <Checkbox
                                checked={order.open}
                                text="Open"
                                type="radio"
                                name="transport-type-cc"
                                // defaultChecked={true}
                                onChange={() => dispatch(setOrder({ ...order, open: true }))}
                            />
                            <Checkbox
                                checked={!order.open}
                                text="Enclosed"
                                type="radio"
                                name="transport-type-cc"
                                onChange={() => dispatch(setOrder({ ...order, open: false }))}
                            />
                        </div>
                    </li>
                </ul>
            </div>
            <hr />
            <div className={s.price}>
                Due now: <strong> ${order.discounted ? discountedPrice || 0 : regularPrice || 0} </strong>
            </div>
            <ReactTooltipCustomized />
        </div>
    )
}

export default Details