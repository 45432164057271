import React, { forwardRef } from 'react'
import InputMask from "react-input-mask"
import Close from '../../../assets/images/icons/Close'
import Lock from '../../../assets/images/icons/Lock'
import s from "./input.module.sass"


const Input = forwardRef(({ label, placeholder, width, type = "text", isClose = false, isCloseCallback, isLock = false, mask, maskChar, disabled, isLabelShow = true, ...props }, ref) => {
    return (
        <label htmlFor={label} className={s.label} style={{ maxWidth: width }}>
            {isLabelShow && label}
            <InputMask
                {...props}
                ref={ref}
                disabled={disabled}
                mask={mask}
                maskChar={maskChar}
                className={s.input}
                type={type}
                id={label}
                placeholder={placeholder}
            />
            {isClose && <Close onClick={isCloseCallback} />}
            {isLock && <Lock />}
        </label>
    )
})

export default Input