import React from 'react'
import s from "./cars-carousel.module.sass"

const CarsCarousel = () => {
    return (
        <section className={s.container__auto}>
			<section className={s.auto}>
				<img src="/images/Cars.svg" alt="carsCarousel" />
			</section>
		</section>
    )
}

export default CarsCarousel