import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CalculateCostLayoutSteps from '../../../components/CalculateCostLayoutSteps/CalculateCostLayoutSteps'
import Button from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/Input/Input'
import { setOrder } from '../../../redux/reducers/mainReducer'
import { parsePhone } from '../../../utils/parsePhone'
import s from "./your-info.module.sass"

const YourInfo = () => {

    const dispatch = useDispatch()

    const ORDER = useSelector(state => state.order)
    const { order } = ORDER

    const [fullName, setFullName] = useState("")

    const [firstPhone, setFirstPhone] = useState({
        isActive: false,
        phone: 0
    })
    const [secondPhone, setSecondPhone] = useState({
        isActive: false,
        phone: 0
    })

    const closeOne = { isClose: true, isCloseCallback: () => setFirstPhone({ ...firstPhone, isActive: false }) }
    const closeTwo = { isClose: true, isCloseCallback: () => setSecondPhone({ ...secondPhone, isActive: false }) }

    const navigate = useNavigate()

    return (
        <CalculateCostLayoutSteps>
            <form className={s.your__info} onSubmit={event => event.preventDefault()}>
                <h1>Your info</h1>
                <div className={s.column}>
                    <Input
                        label="Your full name"
                        placeholder="John Doe"
                        onChange={event => setFullName(event.target.value)}
                    />
                    <Input
                        label="Email"
                        placeholder="mail@gmail.com"
                        value={order.email}
                        onChange={event => dispatch(setOrder({ ...order, email: event.target.value }))}
                    />
                    <Input
                        label="Phone"
                        placeholder="(___)___-____"
                        type="tel"
                        mask="(999)999-9999"
                        value={order.phones[0] || order.phone}
                        onChange={event => dispatch(setOrder({ ...order, phone: parsePhone(event.target.value) }))}
                    />
                </div>
                {!firstPhone.isActive && !secondPhone.isActive &&
                    <Button
                        type="transparent"
                        width="22.8rem"
                        height="3rem"
                        onClick={() => setFirstPhone({ ...firstPhone, isActive: true })}
                    >
                        +add another number <span>(optional)</span>
                    </Button>
                }
                {firstPhone.isActive &&
                    <Input
                        {...closeOne}
                        placeholder="(___)___-____"
                        type="tel"
                        mask="(999)999-9999"
                        onChange={event => setFirstPhone({ ...firstPhone, phone: parsePhone(event.target.value) })}
                    />
                }
                {secondPhone.isActive &&
                    <Input
                        {...closeTwo}
                        placeholder="(___)___-____"
                        mask="(999)999-9999"
                        type="tel"
                        onChange={event => setSecondPhone({ ...secondPhone, phone: parsePhone(event.target.value) })}
                    />
                }
                {!secondPhone.isActive && firstPhone.isActive &&
                    <Button
                        type="transparent"
                        width="22.8rem"
                        height="3rem"
                        onClick={() => setSecondPhone({ ...secondPhone, isActive: true })}
                    >
                        +add another number <span>(optional)</span>
                    </Button>
                }
                <Button disabled={fullName && fullName.includes(" ") ? false : true} onClick={() => {
                    dispatch(setOrder({
                        ...order, phones: [
                            order?.phones[0] || order.phone,
                            firstPhone.phone || undefined,
                            secondPhone.phone || undefined
                        ].filter(phone => phone ),
                        full_name: fullName
                    }))
                    navigate(`/calculate-cost/${order.id}/pickup`)
                }}>
                    NEXT STEP
                </Button>
            </form>
        </CalculateCostLayoutSteps>
    )
}

export default YourInfo