import React from 'react'
import { useSelector } from 'react-redux'

export default function Sun({ ...props }) {

    let color = "#1E7FF0"

    const THEME = useSelector(state => state.theme)

    if (THEME.theme === "dark") color = "#DCE2F9"

    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 4.99071C7.23784 4.99071 4.99072 7.23827 4.99072 10.0005C4.99072 12.7626 7.23784 15.0102 10 15.0102C12.7618 15.0102 15.0093 12.7631 15.0093 10.0005C15.0093 7.23783 12.7618 4.99071 10 4.99071Z" fill={color} />
            <path d="M9.99988 3.51816C9.46002 3.51816 9.02246 3.0806 9.02246 2.54119V0.977414C9.02246 0.437555 9.46002 0 9.99988 0C10.5397 0 10.9773 0.437555 10.9773 0.977414V2.54119C10.9773 3.0806 10.5393 3.51816 9.99988 3.51816Z" fill={color} />
            <path d="M9.99988 16.4814C9.46002 16.4814 9.02246 16.919 9.02246 17.4588V19.0221C9.02246 19.5624 9.46002 20 9.99988 20C10.5397 20 10.9773 19.5624 10.9773 19.0221V17.4588C10.9773 16.919 10.5393 16.4814 9.99988 16.4814Z" fill={color} />
            <path d="M14.5829 5.41674C14.2015 5.03499 14.2015 4.4163 14.5829 4.03454L15.6887 2.9287C16.07 2.54739 16.6891 2.54739 17.0709 2.9287C17.4527 3.31045 17.4527 3.92958 17.0709 4.31089L15.9651 5.41674C15.5837 5.79849 14.9651 5.79849 14.5829 5.41674Z" fill={color} />
            <path d="M5.41677 14.5837C5.03502 14.2015 4.41633 14.2015 4.03457 14.5837L2.92873 15.6891C2.54742 16.0704 2.54697 16.69 2.92873 17.0713C3.31048 17.4526 3.92961 17.4526 4.31092 17.0713L5.41677 15.965C5.79852 15.5837 5.79852 14.9646 5.41677 14.5837Z" fill={color} />
            <path d="M16.4812 10C16.4812 9.46014 16.9188 9.02258 17.4586 9.02258H19.0224C19.5622 9.02258 19.9998 9.46014 19.9998 10C19.9998 10.5399 19.5622 10.977 19.0224 10.977H17.4586C16.9188 10.977 16.4812 10.5399 16.4812 10Z" fill={color} />
            <path d="M3.51816 10C3.51816 9.46014 3.0806 9.02258 2.54074 9.02258H0.977414C0.437555 9.02258 0 9.46014 0 10C0 10.5399 0.437555 10.977 0.977414 10.977H2.54119C3.0806 10.977 3.51816 10.5399 3.51816 10Z" fill={color} />
            <path d="M14.5829 14.5837C14.9647 14.2024 15.5838 14.2024 15.9651 14.5837L17.071 15.6895C17.4527 16.0704 17.4527 16.69 17.071 17.0713C16.6892 17.4526 16.0705 17.4526 15.6888 17.0713L14.5829 15.9655C14.2012 15.5837 14.2012 14.965 14.5829 14.5837Z" fill={color} />
            <path d="M5.41669 5.41674C5.79845 5.03498 5.79845 4.41629 5.41669 4.03454L4.31085 2.92914C3.92909 2.54738 3.3104 2.54738 2.92865 2.92914C2.5469 3.31045 2.5469 3.92958 2.92865 4.31089L4.0345 5.41674C4.41625 5.79893 5.03494 5.79893 5.41669 5.41674Z" fill={color} />
        </svg>

    )
}
