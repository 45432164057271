import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Background from '../../components/Background/Background'
import Form from '../../components/Form/Form'
import Layout from '../../components/Layout/Layout'
import Button from '../../components/UI/Button/Button'
import Checkbox from '../../components/UI/Checkbox/Checkbox'
import Input from '../../components/UI/Input/Input'
import { setOrder } from '../../redux/reducers/mainReducer'
import { parsePhone } from '../../utils/parsePhone'
import { validatePhone } from '../../utils/validatePhone'
import s from "./main-three.module.sass"
import { validateEmail } from '../../utils/validateEmail'
import CustomCalendar from '../../components/UI/Calendar/CustomCalendar'

const MainThree = () => {

    const navigate = useNavigate()

    const [pickupRange, setPickupRange] = useState(new Date())
    const [deliveryRange, setDeliveryRange] = useState(new Date((new Date().getTime() + 86400000)))
    const [asPickupRange, setAsPickupRange] = useState("")
    const [asDeliveryRange, setAsDeliveryRange] = useState("")

    const dispatch = useDispatch()

    const phone = useRef()

    const [asSoonAsOne, setAsSoonAsOne] = useState(false)
    const [asSoonAsTwo, setAsSoonAsTwo] = useState(false)

    const ORDER = useSelector(state => state.order)
    const { order } = ORDER

    return (
        <Layout>
            <Form height="34.5rem">
                <div className={s.main}>
                    <div className={s.row}>
                        <Input
                            label="Email"
                            placeholder="mail@gmail.com"
                            width="100%"
                            type="email"
                            onChange={event => dispatch(setOrder({ ...order, email: event.target.value }))}
                            mask=""
                            maskChar="_"
                        />
                        <Input
                            label="Phone"
                            placeholder="(___)___-____"
                            width="100%"
                            type="tel"
                            ref={phone}
                            mask="(999)999-9999"
                            onChange={event => dispatch(setOrder({ ...order, phones: [parsePhone(event.target.value)] }))}
                        />
                    </div>
                    <div className={s.row__two}>
                        <CustomCalendar
                            date={pickupRange}
                            setDate={setPickupRange}
                            flag="pickup"
                            setMinRange={setDeliveryRange}
                        >
                            Pickup window
                        </CustomCalendar>
                        <CustomCalendar
                            date={deliveryRange}
                            setDate={setDeliveryRange}
                            flag="delivery"
                            minRange={deliveryRange}
                        >
                            Delivery window
                        </CustomCalendar>

                    </div>
                    <div className={s.checkbox__one}>
                        <Checkbox
                            text="As soon as possible"
                            name="main-three-one"
                            onChange={() => {
                                setAsSoonAsOne(!asSoonAsOne)
                                if (!asSoonAsOne) setAsPickupRange([Math.floor((new Date().getTime()) / 1000), Math.floor((new Date().getTime()) / 1000)])
                            }}
                        />
                    </div>
                    <div className={s.checkbox__two}>
                        <Checkbox
                            text="As soon as possible "
                            name="main-three-two"
                            onChange={() => {
                                setAsSoonAsTwo(!asSoonAsTwo)
                                if (!asSoonAsTwo) setAsDeliveryRange([Math.floor((new Date().getTime() + 86400) / 1000), Math.floor((new Date().getTime() + 86400) / 1000)])
                            }}
                        />
                    </div>
                </div>
                <div className={s.private}>
                    <Button
                        width="16.6rem"
                        onClick={async () => {
                            if (validatePhone(order.phones[0], "#Phone") && validateEmail(order.email, "#Email")) {
                                try {
                                    const response = await axios.post("https://apiv2.angick.com/order/calculate", {
                                        address: {
                                            origin: order.address.origin,
                                            destination: order.address.destination
                                        },
                                        details: {
                                            open_trailer: order.open,
                                            vehicle_operable: order.running,
                                            vehicle_modified: order.vehicle_modify
                                        },
                                        information: {
                                            "email": order.email,
                                            "phone": order.phones[0],
                                            "pickup_window": {
                                                "from": asSoonAsOne ? asPickupRange[0] : order.pickupParsed.from,
                                                "to": asSoonAsOne ? asPickupRange[1] : order.pickupParsed.to,
                                            },
                                            "delivery_window": {
                                                "from": asSoonAsTwo ? asDeliveryRange[0] : order.deliveryParsed.from,
                                                "to": asSoonAsTwo ? asDeliveryRange[1] : order.deliveryParsed.to,
                                            }
                                        },
                                        "specification_id": order?.vehicle?.specification_id?.id || undefined,
                                        "model_id": order.vehicle.model_id.id,
                                    })
                                    const data = response.data.order
                                    const fullPrice = data.price + (order.open ? data.trailer_price.open : data.trailer_price.enclosed) + (order.running ? data.condition_price.running : data.condition_price.not_running)
                                    dispatch(setOrder({
                                        ...order,
                                        transit_between: [data.transit_time.min, data.transit_time.max],
                                        miles: data.miles,
                                        address: { origin: data.address.origin, destination: data.address.destination },
                                        condition_price: data.condition_price,
                                        trailer_price: data.trailer_price,
                                        vehicle: { year: data.model.year, model: data.model.make_name, make: data.model.name },
                                        price: fullPrice < data.min_price ? data.min_price : fullPrice,
                                        discount: data.discount,
                                        discountCoefficient: data.discounted_paid_part,
                                        id: data.id,
                                        raw_price: data.price,
                                        min_price: data.min_price
                                    }))
                                    navigate(`/calculate-cost/${data.id}/home/`)
                                } catch (error) {
                                    console.log(error)
                                }
                            }
                        }}
                        disabled={!(order.phones[0] && order.email && (asSoonAsOne ? asPickupRange.length : pickupRange.length) && (asSoonAsTwo ? asDeliveryRange.length : deliveryRange.length))}
                    >
                        CALCULATE COST
                    </Button>
                    <span>
                        By providing your phone number and email, <br />
                        you agree that you have read our <a href="/terms-and-conditions.pdf" target="_blank">Terms</a> & <a href="/privacy-policy.pdf" target="_blank">Privacy Policy</a>
                    </span>
                </div>
            </Form>
            <Background />
        </Layout>
    )
}

export default MainThree