import React from 'react'
import s from "./text.module.sass"

const Text = () => {
    return (
        <div className={s.text}>
            <h1>
                Fast and reliable
                transportation of luxury
                cars in the United States
            </h1>
            <h3>
                We are known for our quality services, exceptional efficiency and the highest level of 
                professionalism. We guarantee to not only meet, but exceed your expectations and 
                ensure your full satisfaction.
            </h3>
        </div>
    )
}

export default Text