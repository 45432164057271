import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CalculateCostLayoutSteps from '../../../components/CalculateCostLayoutSteps/CalculateCostLayoutSteps'
import AutocompleteInput from '../../../components/UI/AutocompleteInput/AutocompleteInput'
import Button from '../../../components/UI/Button/Button'
import Checkbox from '../../../components/UI/Checkbox/Checkbox'
import Input from '../../../components/UI/Input/Input'
import Textarea from '../../../components/UI/Textarea/Textarea'
import { setOrder } from '../../../redux/reducers/mainReducer'
import { parsePhone } from '../../../utils/parsePhone'
import s from "./delivery.module.sass"

const Delivery = () => {

    const [firstPhone, setFirstPhone] = useState({
        isActive: false,
        phone: undefined
    })
    const [secondPhone, setSecondPhone] = useState({
        isActive: false,
        phone: undefined
    })

    const [instruction, setInstruction] = useState(false)

    const [delivery, setDelivery] = useState({
        "address": "",
        "additionally": "" || undefined,
        "business_name": "" || undefined,
        "instruction": "" || undefined,
        "contact": {
            "full_name": "" || undefined,
            "phones": [
                0 || undefined,
                0 || undefined,
                0 || undefined
            ].filter(phone => phone)
        }
    })

    const closeOne = { isClose: true, isCloseCallback: () => setFirstPhone({ ...firstPhone, isActive: false }) }
    const closeTwo = { isClose: true, isCloseCallback: () => setSecondPhone({ ...secondPhone, isActive: false }) }

    const [isBuisness, setIsBuisness] = useState(false)
    const [isIndividual, setIsIndividual] = useState(false)

    const navigate = useNavigate()

    const ORDER = useSelector(state => state.order)
    const { order } = ORDER

    const dispatch = useDispatch()

    return (
        <CalculateCostLayoutSteps>
            <form className={s.delivery} onSubmit={event => event.preventDefault()}>
                <h1>Delivery</h1>
                <AutocompleteInput
                    label="Delivery address"
                    placeholder="Street address"
                    onPlaceSelected={event => setDelivery({ ...delivery, "address": event.formatted_address })}
                    onChange={event => setDelivery({ ...delivery, "address": event.target.value })}
                    types={["address"]}
                />
                <Input
                    placeholder="Apt, Suite, etc. (optional)"
                    onChange={event => setDelivery({ ...delivery, "additionally": event.target.value })}
                />
                <h5>Los Angeles, CA 90011</h5>
                <div className={s.checkboxes}>
                    <h4>This is a</h4>
                    <Checkbox
                        type="radio"
                        text="Residental address"
                        name="address delivery"
                        defaultChecked={true}
                        onChange={() => setIsBuisness(false)}
                    />
                    <Checkbox
                        type="radio"
                        text="Business address"
                        name="address delivery"
                        onChange={() => setIsBuisness(true)}
                    />
                    {isBuisness &&
                        <Input
                            label="Business name"
                            placeholder="Name"
                            onChange={event => setDelivery({ ...delivery, "business_name": event.target.value })}
                        />
                    }
                </div>
                <div className={s.checkboxes}>
                    <h4>Who do we contact about delivery?</h4>
                    <Checkbox
                        type="radio"
                        text="Contact me"
                        name="contact delivery"
                        defaultChecked={true}
                        onChange={() => setIsIndividual(false)}
                    />
                    <Checkbox
                        type="radio"
                        text="Contact someone else"
                        name="contact delivery"
                        onChange={() => setIsIndividual(true)}
                    />
                    {isIndividual &&
                        <div className={s.individual}>
                            <Input
                                label="delivery contact’s full name"
                                placeholder="e.g. John Doe"
                                onChange={event => setDelivery({ ...delivery, "contact": { ...delivery.contact, "full_name": event.target.value } })}
                            />
                            <Input
                                label="Phone number"
                                placeholder="(___)___-____"
                                type="tel"
                                mask="(999)999-9999"
                                onChange={event => setDelivery({
                                    ...delivery, "contact": {
                                        ...delivery.contact, phones: [
                                            parsePhone(event.target.value)
                                        ]
                                    }
                                })}
                            />
                            {!firstPhone.isActive && !secondPhone.isActive &&
                                <Button
                                    type="transparent"
                                    width="22.8rem"
                                    height="3rem"
                                    onClick={() => setFirstPhone({ ...firstPhone, isActive: true })}
                                >
                                    +add another number <span>(optional)</span>
                                </Button>
                            }
                            {firstPhone.isActive &&
                                <Input
                                    {...closeOne}
                                    placeholder="(___)___-____"
                                    type="tel"
                                    mask="(999)999-9999"
                                    onChange={event => setFirstPhone({ ...firstPhone, phone: parsePhone(event.target.value) })}
                                />
                            }
                            {secondPhone.isActive &&
                                <Input
                                    {...closeTwo}
                                    placeholder="(___)___-____"
                                    mask="(999)999-9999"
                                    type="tel"
                                    onChange={event => setSecondPhone({ ...secondPhone, phone: parsePhone(event.target.value) })}
                                />
                            }
                            {!secondPhone.isActive && firstPhone.isActive &&
                                <Button
                                    type="transparent"
                                    width="22.8rem"
                                    height="3rem"
                                    onClick={() => setSecondPhone({ ...secondPhone, isActive: true })}
                                >
                                    +add another number <span>(optional)</span>
                                </Button>
                            }
                        </div>
                    }
                </div>
                <h4 style={{ marginTop: "1rem" }}>Have any special instructions? <span>(optional)</span></h4>
                {!instruction &&
                    <Button
                        type="transparent"
                        onClick={() => setInstruction(true)}
                    >
                        + add instruction
                    </Button>
                }
                {
                    instruction &&
                    <Textarea
                        value={delivery.instruction}
                        setValue={ (value) => setDelivery({ ...delivery, "instruction": value }) }
                    />
                }
                <Button disabled={delivery.address ? false : true} onClick={() => {
                    dispatch(setOrder({ ...order, delivery: { ...delivery, contact: { ...delivery.contact, phones: [...delivery.contact.phones, firstPhone.phone, secondPhone.phone].filter(phone => phone) } } }))
                    navigate(`/calculate-cost/${order.id}/book-shipment`)
                }}>
                    NEXT STEP
                </Button>
            </form>
        </CalculateCostLayoutSteps>
    )
}

export default Delivery