import React from 'react'
import s from "./horizontal-steps-main.module.sass"
import { useLocation } from 'react-router'

export default function HorizontalStepsMain() {

    const steps = [
        { step: 1, text: "Destination", next: <hr /> },
        { step: 2, text: "Vehicle", next: <hr /> },
        { step: 3, text: "Date" },
    ]

    const location = useLocation()

    return (
        <div className={s.steps}>
            {steps.map((step, index) => {

                const classes = [s.wrapper]

                if (index === 0) classes.push(s.active)

                if (location.pathname === "/home/2" && index === 0) classes.push(s.confirmed)
                if (location.pathname === "/home/2" && index === 1) classes.push(s.active)
                if (location.pathname === "/home/3" && index === 0) classes.push(s.confirmed)
                if (location.pathname === "/home/3" && index === 1) classes.push(s.confirmed)
                if (location.pathname === "/home/3" && index === 2) classes.push(s.active)

                return (
                    <div className={classes.join(" ")} key={index}>
                        <div className={s["steps-step"]}>
                            <span>{step.step}</span>
                            <img src="/images/icons/swoosh-step.svg" alt="Swoosh" />
                        </div>
                        <span className={s.unactive}>{step.text}</span>
                        {step?.next}
                    </div>
                )
            })}
        </div>
    )
}
