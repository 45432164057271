import React from 'react'
import HorizontalStepsMain from '../HorizontalStepsMain/HorizontalStepsMain'
import s from "./form.module.sass"

const Form = ({ children, height = "29.1rem", ...props }) => {
    return (
        <form {...props} className={s.form} style={{ height }} onSubmit={(event) => event.preventDefault()}>
            <HorizontalStepsMain />
            {children}
        </form>
    )
}

export default Form