import React from 'react'
import { useParams } from 'react-router'
import s from "./horizontal-steps-calculate-cost.module.sass"

const HorizontalStepsCalculateCost = () => {
    const steps = [
        { step: 1, text: "Your info", next: <hr /> },
        { step: 2, text: "Pickup", next: <hr /> },
        { step: 3, text: "Delivery", next: <hr /> },
        { step: 4, text: "Book shipment", next: <hr /> },
        { step: 5, text: "Thank you" },
    ]

    const params = useParams()

    const { tab } = params

    return (
        <div className={s.steps}>
            {steps.map((step, index) => {

                const classes = [s.wrapper]

                if (index === 0) classes.push(s.active)
                if ((tab === "pickup" || tab === "delivery" || tab === "book-shipment" || tab === "thank-you") && index === 0) classes.push(s.confirmed)
                if ((tab === "pickup" || tab === "delivery") && index === 1) classes.push(s.active)
                if ((tab === "delivery" || tab === "book-shipment" || tab === "thank-you") && index === 1) classes.push(s.confirmed)
                if (tab === "delivery" && index === 2) classes.push(s.active)
                if ((tab === "book-shipment" || tab === "thank-you") && index === 2) classes.push(s.confirmed)
                if (tab === "thank-you" && index === 3) classes.push(s.confirmed)
                if (tab === "book-shipment" && index === 3) classes.push(s.active)
                if (tab === "thank-you" && index === 4) classes.push(s.active)

                return (
                    <div className={classes.join(" ")} key={index}>
                        <div className={s.wrapper__two}>
                            <div className={s["steps-step"]}>
                                <span>{step.step}</span>
                                <img src="/images/icons/swoosh-step.svg" alt="Swoosh" />
                            </div>
                            <span className={s.text}>{step.text}</span>
                        </div>
                        {step?.next}
                    </div>
                )
            })}
        </div>
    )
}

export default HorizontalStepsCalculateCost