import React from 'react'

export default function Lock() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_172_7035" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
                <rect width="26" height="26" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask0_172_7035)">
                <path fillRule="evenodd" clipRule="evenodd" d="M8 11C6.89543 11 6 11.8954 6 13V18C6 19.1046 6.89543 20 8 20H18C19.1046 20 20 19.1046 20 18V13C20 11.8954 19.1046 11 18 11H8ZM13 13.25C12.4477 13.25 12 13.6977 12 14.25V16C12 16.5523 12.4477 17 13 17C13.5523 17 14 16.5523 14 16V14.25C14 13.6977 13.5523 13.25 13 13.25Z" fill="#8B90A4" />
                <path d="M19 12C19 11.2121 18.8448 10.4319 18.5433 9.7039C18.2417 8.97595 17.7998 8.31451 17.2426 7.75736C16.6855 7.20021 16.0241 6.75825 15.2961 6.45672C14.5681 6.15519 13.7879 6 13 6C12.2121 6 11.4319 6.15519 10.7039 6.45672C9.97595 6.75825 9.31451 7.20021 8.75736 7.75736C8.20021 8.31451 7.75825 8.97595 7.45672 9.7039C7.15519 10.4319 7 11.2121 7 12L8.8 12C8.8 11.4484 8.90864 10.9023 9.11971 10.3927C9.33078 9.88316 9.64015 9.42016 10.0302 9.03015C10.4202 8.64015 10.8832 8.33078 11.3927 8.11971C11.9023 7.90864 12.4484 7.8 13 7.8C13.5516 7.8 14.0977 7.90864 14.6073 8.11971C15.1168 8.33078 15.5798 8.64015 15.9698 9.03015C16.3599 9.42016 16.6692 9.88316 16.8803 10.3927C17.0914 10.9023 17.2 11.4484 17.2 12H19Z" fill="#8B90A4" />
            </g>
        </svg>

    )
}
