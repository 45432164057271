import React from 'react'
import s from "./button.module.sass"

const Button = ({ children, width, height, disabled = false ,...props }) => {
    return (
        <button
            {...props}
            // type="button"
            disabled={disabled}
            style={{ maxWidth: width, height }}
            className={s.button}
        >
            {children}
        </button>
    )
}

export default Button