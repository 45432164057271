import React from 'react'
import CarsCarousel from '../UI/CarsCarousel/CarsCarousel'
import s from "./footer.module.sass"

const Footer = () => {
    return (
        <div className={s.footer}>
            <CarsCarousel />
            <footer>
                <div>
                    <span>
                        ©{new Date().getFullYear()} Angick
                    </span>
                    <span>
                        <a href="/terms-and-conditions.pdf" target="_blank">Terms & Conditions</a>
                    </span>
                    <span>
                        <a href="/privacy-policy.pdf" target="_blank">Privacy Policy</a>
                    </span>
                </div>
                <div>
                    <span>
                        <a href="mailto:transport@angick.com" target="_blank">transport@angick.com</a>
                    </span>
                    <span>
                        <a href="tel:+1(313)509-8987">+1(313)509-8987</a>
                    </span>
                </div>
            </footer>
        </div>
    )
}

export default Footer