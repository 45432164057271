import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import Background from '../../components/Background/Background'
import Form from '../../components/Form/Form'
import Layout from '../../components/Layout/Layout'
import Button from '../../components/UI/Button/Button'
import Checkbox from '../../components/UI/Checkbox/Checkbox'
import Select from "../../components/UI/Select/Select"
import Tooltip from '../../components/UI/Tooltip/Tooltip'
import { useFetching } from "../../hooks/useFetching"
import s from "./main-two.module.sass"
import { getYears } from "../../utils/years"
import CarsService from "../../API/CarsService"
import { useDispatch, useSelector } from 'react-redux'
import { setOrder } from '../../redux/reducers/mainReducer'
import ReactTooltip from 'react-tooltip'
import ReactTooltipCustomized from '../../components/ReactTooltipCustomized/ReactTooltipCustomized'

const MainTwo = () => {

    const navigate = useNavigate()

    const [years, setYears] = useState([])

    const [data, setData] = useState({
        models: [],
        makes: [],
        specifications: []
    })

    useEffect(() => { (async () => setYears(await getYears()))() }, [])

    const [year, setYear] = useState("")
    const [make, setMake] = useState("")
    const [model, setModel] = useState("")
    const [specification, setSpecification] = useState({ isValid: false, value: "" })

    const dispatch = useDispatch()

    const ORDER = useSelector(state => state.order)
    const { order } = ORDER

    const [fetchCarMake, isCarMakeLoading] = useFetching(async (year) => {
        const response = await CarsService.getCarMake(year)
        setData({ ...data, makes: response.data.makes.map((item) => item = { label: item.name, value: item.id }) })
    })

    const [fetchCarModel, isCarModelLoading] = useFetching(async (id, year) => {
        const response = await CarsService.getCarModel(id, year)
        setData({ ...data, models: response.data.models.map((item) => item = { label: item.name, value: item.id }) })
    })

    const [fetchCarSpecifications, isCarSpecificationsLoading] = useFetching(async (id) => {
        const response = await CarsService.getCarSpecifications(id)
        if (response.data.specifications.length) {
            setSpecification({ ...specification, isValid: false })
            setData({ ...data, specifications: response.data.specifications.map((item) => item = { label: item.name, value: item.id }) })
        } else {
            setSpecification({ ...specification, isValid: true })
            setData({ ...data, specifications: [] })
        }

    })

    return (
        <Layout>
            <Form height="34.5rem">
                <div className={s.main}>
                    <div className={s.row}>
                        <Select
                            options={years}
                            onChange={(event) => {
                                setMake("")
                                setModel("")
                                dispatch(setOrder({ ...order, vehicle: { ...order.vehicle, year: event.value } }))
                                fetchCarMake(event.value)
                                setYear(event.value)
                            }}
                        >
                            Vehicle year
                        </Select>
                        <Select
                            options={data.makes}
                            isLoading={isCarMakeLoading}
                            onChange={(event) => {
                                setModel("")
                                setMake(event.value)
                                dispatch(setOrder({
                                    ...order, vehicle: {
                                        ...order.vehicle, make_id: {
                                            name: event.label,
                                            id: event.value,
                                        }
                                    }
                                }))
                                fetchCarModel(event.value, order.vehicle.year)
                            }}
                        >
                            Vehicle make
                        </Select>
                        <Button
                            disabled={(year && model && make && specification.isValid) ? false : true}
                            onClick={() => navigate("/home/3")}
                            width="12rem"
                        >
                            NEXT STEP
                        </Button>
                    </div>
                    <div className={s.row__two}>
                        <div className={s.column}>
                            <Select
                                options={data.models}
                                isLoading={isCarModelLoading}
                                onChange={(event) => {
                                    setModel(event.value)
                                    dispatch(setOrder({
                                        ...order, vehicle: {
                                            ...order.vehicle, model_id: {
                                                name: event.label,
                                                id: event.value
                                            }
                                        }
                                    }))
                                    fetchCarSpecifications(event.value)
                                }}
                            >
                                Vehicle model
                            </Select>
                            <div className={s.mt}>
                                <Tooltip text={"Is it operable?"} tooltipBody={`
                                Operational Vehicle means a vehicle that is fully and immediately capable of driving with active motor vehicle registration and that is not in any wrecked, discarded, abandoned, or work-in-progress condition
                                <br/>
                                Inoperable vehicle means any motor vehicle that cannot be started and moved under its own power without the assistance of another person, vehicle, or gravity
                                `} />
                                <div className={s.checkboxes}>
                                    <Checkbox
                                        text="Yes"
                                        defaultChecked={true}
                                        type="radio"
                                        name="main-two"
                                        onChange={() => dispatch(setOrder({ ...ORDER.order, running: true }))}
                                    />
                                    <Checkbox
                                        text="No"
                                        type="radio"
                                        name="main-two"
                                        onChange={() => dispatch(setOrder({ ...ORDER.order, running: false }))}
                                    />
                                </div>
                            </div>
                        </div>
                        {data.specifications.length ?
                            <div className={s.column}>
                                <Select
                                    options={data.specifications}
                                    isLoading={isCarSpecificationsLoading}
                                    onChange={(event) => {
                                        setSpecification({ isValid: true, value: event.label })
                                        dispatch(setOrder({
                                            ...order, vehicle: {
                                                ...order.vehicle, specification_id: {
                                                    name: event.label,
                                                    id: event.value
                                                }
                                            }
                                        }))
                                    }}
                                >
                                    Vehicle specifications
                                </Select>
                                <div className={s.mt}>
                                    <Tooltip text="Vehicle modify?" tooltipBody={`The definition of a car modification is a 
                                    change made to a vehicle so that it differs from the manufacturers original factory specification`} />
                                    <div className={s.checkboxes}>
                                        <Checkbox
                                            checked={order.vehicle_modify}
                                            text="Yes"
                                            type="radio"
                                            id="modify-yes"
                                            name="modify"
                                            onChange={() => dispatch(setOrder({ ...ORDER.order, vehicle_modify: true }))}
                                        />
                                        <Checkbox
                                            text="No"
                                            type="radio"
                                            id="modify-no"
                                            checked={!order.vehicle_modify}
                                            name="modify"
                                            onChange={() => dispatch(setOrder({ ...ORDER.order, vehicle_modify: false }))}
                                        />
                                    </div>
                                    <ReactTooltipCustomized />
                                </div>
                            </div> : <></>
                        }
                    </div>
                </div>
            </Form>
            <Background />
            <ReactTooltipCustomized />
        </Layout>
    )
}

export default MainTwo