import React from 'react'
import AutoComplete from 'react-google-autocomplete'
import s from "./autocomplete-input.module.sass"

const AutocompleteInput = React.forwardRef((props, ref) => {

    return (
        <label htmlFor={props.label} className={s.label} style={{ maxWidth: props.width }}>
            {props.label}
            <AutoComplete
                {...props}
                // required
                className={s.input}
                language="en"
                ref={ref}
                placeholder={props.placeholder || "Zip or City"}
                options={{
                    componentRestrictions: { country: "us" },
                    types: props.types || ["(regions)"],
                }}
                onChange={props.onChange}
                onPlaceSelected={props.onPlaceSelected}
            />
        </label>
    )
})

export default AutocompleteInput