import React from 'react'

export default function Close({ ...props }) {
    return (
        <svg width="26" {...props} height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_172_1226" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
                <rect width="26" height="26" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask0_172_1226)">
                <path d="M8 8L13 13M18 18L13 13M13 13L18 8L8 18" stroke="#8B90A4" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>

    )
}
