import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import MainOne from "../pages/MainOne/MainOne"
import MainThree from '../pages/MainThree/MainThree'
import MainTwo from '../pages/MainTwo/MainTwo'
import CalculateCost from '../pages/CalculateCost/CalculateCost'
import ContactUs from "../pages/ContactUs/ContactUs"
import OurServices from "../pages/OurServices/OurServices"
import HowDoesItWork from "../pages/HowDoesItWork/HowDoesItWork"

const AppRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<MainOne />} />
            <Route path="/home/2" element={<MainTwo />} />
            <Route path="/home/3" element={<MainThree />} />
            <Route path="/calculate-cost/:id/:tab" element={<CalculateCost />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/our-services" element={<OurServices />} />
            <Route path="/how-does-it-work" element={<HowDoesItWork />} />
            <Route path="*" element={<MainOne />} />
        </Routes>
    )
}

export default AppRoutes