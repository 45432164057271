import React from 'react'
import CalculateCostLayout from '../CalculateCostLayout/CalculateCostLayout'
import HorizontalStepsCalculateCost from '../HorizontalStepsCalculateCost/HorizontalStepsCalculateCost'
import s from "./calculate-cost-layout-steps.module.sass"

const CalculateCostLayoutSteps = ({ children }) => {
    return (
        <CalculateCostLayout>
            <div className={s.layout}>
                <div className={s.steps}>
                    <HorizontalStepsCalculateCost />
                </div>
                {children}
            </div>
        </CalculateCostLayout>
    )
}

export default CalculateCostLayoutSteps