import { configureStore } from '@reduxjs/toolkit'
import mainReducer from '../reducers/mainReducer'
import themeReducer from '../reducers/themeReducer'

export const store = configureStore({
    reducer: {
        theme: themeReducer,
        order: mainReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})