import axios from "axios"

export default class CarService {
    static async getCarMake(year) {
        const response = await axios.get(`https://apiv2.angick.com/makes/${year}`)
        return response
    }

    static async getCarModel(make_id, year) {
        const response = await axios.get(`https://apiv2.angick.com/models`, { params: { make_id, year } })
        return response
    }

    static async getCarSpecifications(model_id) {
        const response = await axios.get(`https://apiv2.angick.com/specifications`, { params: { model_id } })
        return response
    }
}