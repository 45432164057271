import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import HorizontalStepsCalculateCost from '../../../components/HorizontalStepsCalculateCost/HorizontalStepsCalculateCost'
import s from "./thank-you.module.sass"

const ThankYou = () => {

    const { order } = useSelector(state => state.order)

    const params = useParams()

    return (
        <div className={s.wrapper}>
            <HorizontalStepsCalculateCost />
            <div className={s.thank__you}>
                <h1>Thank you <img src="/images/icons/smile.svg" alt="Smile" /> </h1>
                <h4>Order <span>#{params.id}</span></h4>
                <p>If you have any additional questions, you can contact us by phone: <a href="tel:+1(313)509-8987">+1(313)509-8987</a> or email: <a href="mailto:transport@angick.com" target="_blank">transport@angick.com</a> </p>
            </div>
        </div>
    )
}

export default ThankYou