import React from 'react'
import { useParams } from 'react-router'
import Choose from './Choose/Choose'
import Pickup from './Pickup/Pickup'
import YourInfo from './YourInfo/YourInfo'
import Delivery from './Delivery/Delivery'
import BookShipment from './BookShipment/BookShipment'
import ThankYou from './ThankYou/ThankYou'

const CalculateCost = () => {

    const params = useParams()

    return (
        params.tab === "home" && <Choose /> ||
        params.tab === "your-info" && <YourInfo /> ||
        params.tab === "pickup" && <Pickup /> ||
        params.tab === "delivery" && <Delivery /> ||
        params.tab === "book-shipment" && <BookShipment /> ||
        params.tab === "thank-you" && <ThankYou />
    )
}

export default CalculateCost