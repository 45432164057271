import React from 'react'
import Logo from '../../assets/images/Logo'
import LogoWhite from '../../assets/images/LogoWhite'
import { NavLink, useLocation } from "react-router-dom"
import s from "./header.module.sass"
import Sun from '../../assets/images/icons/Sun'
import Moon from '../../assets/images/icons/Moon'
import { useDispatch, useSelector } from "react-redux"
import { setTheme } from '../../redux/reducers/themeReducer'

const Header = () => {

    const dispatch = useDispatch()

    const THEME = useSelector(state => state.theme)

    const location = useLocation()

    const { pathname } = location

    const navItems = [
        { link: "/", name: "HOME" },
        { link: "/our-services", name: "OUR SERVICES" },
        { link: "/how-does-it-work", name: "HOW DOES IT WORK?" },
        { link: "/contact-us", name: "CONTACT US" },
    ]

    return (
        <header className={`${s.header}`}>
            <nav>
                <div className={s.first}>
                    {THEME.theme === "white" ?
                        <NavLink to="/"><Logo className={s.logo} /></NavLink> :
                        <NavLink to="/"><LogoWhite className={s.logo} /></NavLink>
                    }
                    <div className={`${s["header-links"]} ${s.adaptive}`}>
                        {navItems.map((item, index) => {

                            const classes = [s.link]

                            if (pathname === item.link) classes.push(s.active)
                            if (pathname === "/home/2" && index === 0) classes.push(s.active)
                            if (pathname === "/home/3" && index === 0) classes.push(s.active)

                            return (
                                <NavLink to={item.link} key={index}>
                                    <span className={classes.join(" ")}>{item.name}</span>
                                </NavLink>
                            )
                        })}
                        <div className={s.theme}>
                            <Sun onClick={() => {
                                dispatch(setTheme("white"))
                                document.body.className = "white"
                            }} />
                            <Moon onClick={() => {
                                dispatch(setTheme("dark"))
                                document.body.className = "dark"
                            }} />
                        </div>
                    </div>
                </div>
                <a href="tel:+13135098987" className={`${s.phone}`}>+1(313)509-8987</a>
                <div className={s.burger_menu}>
                    <input type="checkbox" />
                    <span className={s.hr}></span>
                    <span className={s.hr}></span>
                    <span className={s.hr}></span>
                    <div className={s.menu}>
                        <section className={s.menu_links}>
                            {navItems.map((item, index) => {

                                const classes = [s.link]

                                if (pathname === item.link) classes.push(s.active)
                                if (pathname === "/home/2" && index === 0) classes.push(s.active)
                                if (pathname === "/home/3" && index === 0) classes.push(s.active)

                                return (
                                    <NavLink to={item.link} key={index}>
                                        <span className={classes.join(" ")}>{item.name}</span>
                                    </NavLink>
                                )
                            })}
                        </section>
                        <section className={s.menu_themes}>
                            <Sun onClick={() => {
                                dispatch(setTheme("white"))
                                document.body.className = "white"
                            }} />
                            <Moon onClick={() => {
                                dispatch(setTheme("dark"))
                                document.body.className = "dark"
                            }} />
                        </section>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header