import React from 'react'
import s from "./checkbox.module.sass"

const Checkbox = ({ text, type = "checkbox", name, id = "", jsx, ...props }) => {
    return (
        <div className={s.custom__checkbox}>
            <input {...props} id={ id ? id : text } type={type} name={name} />
            <label htmlFor={id ? id : text}> {text} </label>
        </div>
    )
}

export default Checkbox