import React, { useState } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { setOrder } from '../../../redux/reducers/mainReducer';
import "./calendar.css"
import s from "./calendar.module.sass"

const CustomCalendar = ({ children, date, setDate, flag, setMinRange, minRange }) => {

    const [click, setClick] = useState(false)

    const dispatch = useDispatch()

    const { order } = useSelector(state => state.order)

    try {
        return (
            <label className={s.label}>
                {children}
                <div className={s.block} onClick={() => setClick(!click)}>
                    {!click && !date.length && <>Calendar</>}
                    {
                        date.length &&
                        <>from {date.length && date[0].toLocaleDateString()} to {date.length && date[1].toLocaleDateString()}</>
                    }
                </div>
                {click &&
                    <Calendar
                        className={s.calendar}
                        selectRange={true}
                        value={date}
                        onChange={event => {
                            setDate(event)
                            setClick(false)
                            if (flag === "pickup") {
                                dispatch(setOrder({
                                    ...order, pickupParsed: {
                                        from: Math.floor(event[0].getTime() / 1000),
                                        to: Math.floor(event[1].getTime() / 1000),
                                    }
                                }))
                                setMinRange(new Date((event[0].getTime() + 86400000)))
                            } else {
                                dispatch(setOrder({
                                    ...order, deliveryParsed: {
                                        from: Math.floor(event[0].getTime() / 1000),
                                        to: Math.floor(event[1].getTime() / 1000),
                                    }
                                }))
                            }
                        }}
                        minDate={flag === "pickup" ? new Date() : !Array.isArray(minRange) ? minRange : minRange[0] }
                    />
                }
            </label>
        )
    } catch (error) {

    }
}

export default CustomCalendar